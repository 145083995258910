import { useEffect } from 'react';

import { useDonationContextProvider } from 'context/DonationContextProvider';

import { DONATION_STEPS } from './data';

import { DonorInformationPage } from './donor-information';
import { DonationPaymentPage } from './DonationPayment';
import { DonationThanksPage } from './DonationThanks';
import { DonationHeader } from './DonationHeader';
import { QuickDonationForm } from './QuickDonationForm';
import { PackageList } from './PackageList';
import { DonationFooter } from './DonationFooter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Home = () => {
  const { donationStep } = useDonationContextProvider();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [donationStep]);

  useEffect(() => {
    document.body.style.backgroundColor = '#fff';
  }, []);

  switch (donationStep) {
    case DONATION_STEPS.DONOR_INFORMATION:
      return <DonorInformationPage />;
    case DONATION_STEPS.PAYMENT:
      return <DonationPaymentPage />;
    case DONATION_STEPS.THANKS:
      return <DonationThanksPage />;
  }

  return (
    <div className="d-flex flex-column min-vh-100 ">
      <DonationHeader />
      <main className="flex-1 d-flex flex-column container">
        <div className="mt-4">
          {/* <div className="d-flex align-items-center"> */}
          <h3 className="text-black text-left my-4">
            <FontAwesomeIcon
              icon="fa-solid fa-hand-holding-heart"
              className="text-danger me-2 text-body-2"
            />
            {/* <Icon icon={['fas', 'heart']} className="text-danger me-2" /> */}
            Donate Now
          </h3>
          {/* </div> */}
          <QuickDonationForm />
        </div>
        <div className="flex-1  py-4 ">
          <PackageList />
        </div>
      </main>
      <DonationFooter />
    </div>
  );
};

export default Home;
