import { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SponsorshipItemSchema, sponsorshipScheme } from './sponsorshipScheme';

import Flex from 'components/common/Flex';
import CreateSponsorNav from './CreateSponsorNav';
import IconButton from 'components/common/IconButton';
import { Card, Col, Row } from 'react-bootstrap';
import LoadingButton from 'components/common/LoadingButton';
import SelectBeneficiaryModal from './SelectBeneficiaryModal';
import SponsorshipItemsForm from './SponsorshipItemsForm';

import FalconCardHeader from 'components/common/FalconCardHeader';
import { FormProvider, RHFDatePicker } from 'components/common/form';

const SponsorshipForm = props => {
  const { /*onSubmit,*/ isEdit, loading } = props;

  const [isSelectBeneficiaryModalOpen, setIsSelectBeneficiaryModalOpen] =
    useState(false);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const beneficiaryId =
    searchParams.get('beneficiaryId') || searchParams.get('id');

  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      beginOn: '',
      items: []
    },
    resolver: yupResolver(sponsorshipScheme)
  });
  const sponsorshipItemsForm = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: { donorId: null, donationId: null, orderItemId: null },
    resolver: yupResolver(SponsorshipItemSchema)
  });

  const { handleSubmit } = form;
  const sponsorshipItems = form.watch('items');

  const totalAmount = sponsorshipItems.reduce(
    (acc, curr) => acc + curr.orderItem.amount,
    0
  );

  const submitHandler = data => {
    console.log(data);
    // onSubmit({ ...data, beneficiaryId });
  };

  const handleChangeBeneficiary = () => {
    setIsSelectBeneficiaryModalOpen(true);
  };

  const onSelectBeneficiary = selectedBeneficiaryId => {
    searchParams.set('beneficiaryId', selectedBeneficiaryId);
    setSearchParams(searchParams);
  };

  const onAddSponsorshipItem = data => {
    const currentItems = form.getValues('items');
    form.setValue('items', [...currentItems, data]);
    sponsorshipItemsForm.reset();
  };

  const onEdit = index => {
    const itemToEdit = form.getValues('items')[index];
    sponsorshipItemsForm.reset({
      donorId: itemToEdit.donor.id,
      donationId: itemToEdit.donation.id,
      orderItemId: itemToEdit.orderItem.id
    });
  };
  const onRemove = index => {
    const currentItems = form.getValues('items');
    currentItems.splice(index, 1);
    form.setValue('items', [...currentItems]);
    sponsorshipItemsForm.reset({
      donorId: null,
      donationId: null,
      orderItemId: null
    });
  };

  return (
    <>
      <SelectBeneficiaryModal
        onHide={() => setIsSelectBeneficiaryModalOpen(false)}
        show={isSelectBeneficiaryModalOpen}
        onSubmit={onSelectBeneficiary}
        beneficiaryId={beneficiaryId}
      />

      <div className="py-0">
        <Row className="g-3">
          <Col md={4} lg={3}>
            <CreateSponsorNav onChangeBeneficiary={handleChangeBeneficiary} />
          </Col>
          <Col md={8} lg={9}>
            <FormProvider methods={form} onSubmit={handleSubmit(submitHandler)}>
              <Card className="mb-3">
                <FalconCardHeader
                  title={
                    <Flex>
                      <IconButton
                        variant="default"
                        className="shadow-none text-primary p-0"
                        iconClassName={'me-3'}
                        icon={'arrow-left'}
                        onClick={() => navigate(-1)}
                      />
                      <h4 className="mt-2 fw-bold fs-0  fs-md-2">
                        {isEdit ? 'Update' : 'Create'} Sponsorship
                      </h4>
                    </Flex>
                  }
                  light={false}
                  titleTag="div"
                  menuClassName="align-items-center"
                  endEl={
                    <Flex alignItems={'center'}>
                      <LoadingButton
                        transform="shrink-3"
                        className="me-2"
                        type="submit"
                        loading={loading}
                      >
                        {isEdit ? 'Update' : 'Save'}
                      </LoadingButton>
                    </Flex>
                  }
                />
              </Card>

              <Card className="mb-3">
                <Card.Body>
                  <Row>
                    <Col as={Col} md={6} className="mb-3">
                      <RHFDatePicker name="beginOn" label="Begin on" required />
                    </Col>
                  </Row>
                  <div>
                    <Controller
                      name="items"
                      control={form.control}
                      render={() => (
                        <SponsorshipItemsForm
                          form={sponsorshipItemsForm}
                          onSubmit={onAddSponsorshipItem}
                        />
                      )}
                    />
                    <p className="text-danger">
                      {form.formState.errors.items?.message}
                    </p>
                  </div>
                </Card.Body>
              </Card>

              <Card>
                <Card.Header className="pb-0">
                  <Card.Title className="fw-medium">
                    Added Order Items
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  {sponsorshipItems.length === 0 && (
                    <h5 className="text-center text-500">No items added</h5>
                  )}
                  {sponsorshipItems?.map(({ donor, orderItem }, index) => (
                    <Flex
                      key={index}
                      justifyContent={'between'}
                      className="border rounded-3 p-3 mb-3"
                    >
                      <div>
                        <p className="mb-1 fs-0 fw-medium">{donor.name}</p>
                        <p className="mb-0 fw-medium">
                          Amount :{' '}
                          <span className="text-primary">
                            ${orderItem.amount}
                          </span>
                        </p>
                        <p className="mb-0 fw-medium">
                          Billing Condition :{' '}
                          <span className="text-primary">
                            {orderItem.billing_condition}
                          </span>
                        </p>
                      </div>
                      <Flex>
                        <IconButton
                          icon={'pen'}
                          iconClassName="me-1"
                          variant="link"
                          className="p-0"
                          onClick={() => onEdit(index)}
                        >
                          <span className="d-none d-md-inline-block">Edit</span>
                        </IconButton>
                        <IconButton
                          icon={'trash'}
                          iconClassName="text-danger"
                          variant="link"
                          className="p-0 ms-3"
                          onClick={() => onRemove(index)}
                        />
                      </Flex>
                    </Flex>
                  ))}
                  <hr />
                  <Flex justifyContent={'between'}>
                    <div>
                      <p className="fs-0 mb-0 text-1000 fw-semi-bold">
                        Total amount
                      </p>
                    </div>
                    <div>
                      <p className="fs-0 mb-0 text-1000 fw-semi-bold text-end">
                        ${totalAmount.toFixed(2)}
                      </p>
                    </div>
                  </Flex>
                </Card.Body>
              </Card>
            </FormProvider>
          </Col>
        </Row>
      </div>
    </>
  );
};

SponsorshipForm.propTypes = {
  isEdit: PropTypes.bool,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func
};

export default SponsorshipForm;
