import { Button, Card, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useContext, useMemo, useEffect } from 'react';
import { RHFControl, RHFSelect } from 'components/common/form';
import {
  PAYMENT_OPTION_MONTHLY,
  PAYMENT_OPTION_ONE_OFF
} from 'components/donations/donation-packages/package-form/PackageForm';
import AuthContext from 'context/authContext';
import {
  GET_DONATION_AMOUNTS,
  GET_DONATION_TYPES,
  GET_LOCATIONS
} from 'components/donations/donation-packages/package-form/package-options-queries';
import { locationTypesMap } from 'components/donations/donation-settings/Locations';

/* -------------------------------------------------------------------------- */
const donationDetailsScheme = Yup.object().shape({
  billingCondition: Yup.string().required('Required Field'),
  donationTypeId: Yup.string().required('Required Field'),
  locationIdList: Yup.array()
    .of(Yup.string())
    .min(1, 'Must select at least one location')
    .required('This field is required'),
  selectedPackage: Yup.string().required('Required Field'),
  donationAmount: Yup.string().required('Required Field'),
  customAmount: Yup.string().when('donationAmount', {
    is: 'custom',
    then: () =>
      Yup.number()
        .typeError('Please enter valid amount')
        .required('Required Field')
  })
});

const billingConditionsOptions = [
  {
    label: 'One-Off',
    value: PAYMENT_OPTION_ONE_OFF
  },
  {
    label: 'Monthly',
    value: PAYMENT_OPTION_MONTHLY
  }
];
/* -------------------------------------------------------------------------- */
export const GET_PACKAGES = gql`
  query GetPackages(
    $organizationId: uuid!
    $paymentOption: jsonb = ""
    $donationTypeId: jsonb = ""
    $locationIdList: jsonb = ""
  ) {
    packages(
      where: {
        organisation_id: { _eq: $organizationId }
        payment_options: { _contains: $paymentOption }
        donation_type: { _contains: $donationTypeId }
        location: { _contains: $locationIdList }
      }
    ) {
      currency_id
      donation_amount
      donation_type
      location
      id
      organisation_id
      package_category
      package_description
      package_title
      payment_options
      redirect_url
      status
      transaction_fee
      branch_id
    }
  }
`;
/* -------------------------------------------------------------------------- */

const ADDonationDetails = ({ onSubmit, data }) => {
  const { organization, selectedBranchId } = useContext(AuthContext);
  const { data: donationTypesData, loading: donationTypesLoading } = useQuery(
    GET_DONATION_TYPES,
    {
      variables: {
        organizationId: organization.id,
        branchId: selectedBranchId
      }
    }
  );
  const { data: locationsData, loading: locationsLoading } = useQuery(
    GET_LOCATIONS,
    {
      variables: {
        type: locationTypesMap.project,
        organizationId: organization.id,
        branchId: selectedBranchId
      }
    }
  );
  const [getPackages, { data: packagesData, loading: packagesLoading }] =
    useLazyQuery(GET_PACKAGES);
  const { data: donationAmountsData, loading: donationAmountsLoading } =
    useQuery(GET_DONATION_AMOUNTS, {
      variables: {
        organizationId: organization.id,
        branchId: selectedBranchId
      }
    });
  const defaultValues = {
    billingCondition: PAYMENT_OPTION_ONE_OFF,
    donationTypeId: '',
    locationIdList: [],
    selectedPackage: '',
    donationAmount: 'custom',
    customAmount: ''
  };
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    enableReinitialize: true,
    defaultValues,
    resolver: yupResolver(donationDetailsScheme)
  });
  const { handleSubmit: RHFHandleSubmit, setValue, watch, reset } = methods;
  const {
    donationTypeId,
    billingCondition,
    selectedPackage,
    donationAmount,
    locationIdList
  } = watch();

  useEffect(() => {
    getPackages({
      variables: {
        organizationId: organization.id,
        paymentOption: billingCondition,
        locationIdList,
        donationTypeId
      }
    });
  }, [
    organization,
    donationTypeId,
    locationIdList,
    billingCondition,
    getPackages
  ]);
  useEffect(() => {
    if (data) {
      reset({ ...data, donationTypeId: data.type.id });
    }
  }, [data, reset]);
  const donationTypesOptions = useMemo(
    () =>
      donationTypesData?.donation_type?.map(({ id, donation_type_name }) => ({
        label: donation_type_name,
        value: id
      })),
    [donationTypesData]
  );
  const packagesOptions = useMemo(
    () =>
      packagesData?.packages?.map(({ id, package_title }) => ({
        label: package_title,
        value: id
      })),
    [packagesData]
  );
  const locationsOptions = useMemo(() => {
    const options = locationsData?.location?.map(({ id, location_name }) => ({
      label: location_name,
      value: id
    }));
    return [{ label: 'All', value: '*' }, ...(options || [])];
  }, [locationsData]);
  const donationAmountsOptions = useMemo(() => {
    const options = [];
    const selectedPackageData = packagesData?.packages?.find(
      ({ id }) => id === selectedPackage
    );
    if (selectedPackageData) {
      const { donation_amount } = selectedPackageData;
      donation_amount.forEach(donation_amount_id => {
        const donationAmountData = donationAmountsData?.donation_amount?.find(
          ({ id }) => id === donation_amount_id
        );
        if (donationAmountData) {
          const { amount, description } = donationAmountData;
          options.push({
            label: `$${amount}` + (description ? ` - (${description})` : ''),
            value: donation_amount_id
          });
        }
      });
    }
    // TODO: Check if the package supports the custom amount
    options.push({
      label: 'Custom',
      value: 'custom'
    });
    return options;
  }, [donationAmountsData, packagesData, selectedPackage]);

  const handleSubmit = data => {
    let amount;
    if (data.donationAmount === 'custom') {
      amount = +data.customAmount;
    } else {
      const donationAmountData = donationAmountsData?.donation_amount?.find(
        ({ id }) => id === data.donationAmount
      );
      amount = donationAmountData?.amount;
    }
    const donationType = {
      id: data.donationTypeId,
      name: donationTypesData?.donation_type?.find(
        ({ id }) => id === data.donationTypeId
      )?.donation_type_name
    };
    const name = packagesData?.packages?.find(
      ({ id }) => id === data.selectedPackage
    )?.package_title;
    // const country = packagesData?.packages?.find(
    //   ({ id }) => id === data.selectedPackage
    // )?.location;
    const locationNameList = data.locationIdList.map(locationId => {
      return locationsData?.location?.find(({ id }) => id === locationId)
        ?.location_name;
    });

    const submitData = {
      values: data,
      amount,
      type: donationType,
      name,
      packageId: data.selectedPackage,
      locations: locationNameList
      // country: data.country
    };
    onSubmit(submitData);
    reset(defaultValues);
  };

  useEffect(() => {
    if (donationTypesData) {
      const generalType = donationTypesData.donation_type.find(
        type => type.donation_type_name === 'General'
      );
      setValue('donationTypeId', generalType?.id || '');
    }
    if (locationsData) {
      setValue(
        'locationIdList',
        locationsData?.location?.map(location => location.id)
      );
    }
  }, [donationTypesData, locationsData, setValue]);

  return (
    <Card className="mb-3">
      <Card.Header className="pb-0">
        <Card.Title className="fw-medium">
          Donation Details / Allocations
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <FormProvider {...methods}>
          <Row className="g-3">
            <Col sm={12} md={6}>
              <RHFSelect
                label="Billing Conditions"
                name={'billingCondition'}
                options={billingConditionsOptions}
                required
              />
            </Col>
            <Col sm={12} md={6}>
              <RHFSelect
                label="Donation Type"
                name={'donationTypeId'}
                isLoading={donationTypesLoading}
                options={donationTypesOptions}
                required
              />
            </Col>
            <Col sm={12} md={6}>
              <RHFSelect
                isMulti={true}
                allowSelectAll={true}
                label="Location"
                name={'locationIdList'}
                isLoading={locationsLoading}
                options={locationsOptions}
                required
              />
            </Col>
            <Col sm={12}>
              <RHFSelect
                label="Current Appeals"
                name={'selectedPackage'}
                isLoading={packagesLoading}
                options={packagesOptions}
                required
              />
            </Col>
            {/* <Form.Group as={Col} md={12}>
            <Form.Label>Fund Available Appeals</Form.Label>
            <InputGroup>
              <InputGroup.Text
                id="basic-addon1"
                className="bg-transparent border-end-0 text-400"
              >
                <FontAwesomeIcon icon={'search'} />
              </InputGroup.Text>
              <FormControl
                className="border-start-0"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
          </Form.Group> */}
            <Col sm={12} md={6}>
              <RHFSelect
                label="Amount"
                name={'donationAmount'}
                options={donationAmountsOptions}
                isLoading={donationAmountsLoading}
                required
              />
            </Col>
            {donationAmount === 'custom' && (
              <Col sm={12} md={6}>
                <RHFControl
                  label="Custom Amount"
                  placeholder="0.00"
                  name={'customAmount'}
                  required
                />
              </Col>
            )}

            {/* <Form.Group as={Col} md={3}>
            <Form.Label>Currency</Form.Label>
            <ReactSelect
              closeMenuOnSelect={true}
              options={donationCurrencyData}
              placeholder="Select"
              classNamePrefix="react-select"
              value={donationCurrency}
              onChange={value => setDonationCurrency(value)}
            />
            <Form.Control.Feedback type="invalid">
              Required
            </Form.Control.Feedback>
          </Form.Group> */}
          </Row>
          <Button
            size="lg"
            // type="submit"
            className="fs-0 fs-md-1 w-100 py-3 mt-4"
            onClick={RHFHandleSubmit(handleSubmit)}
          >
            {data ? 'Update' : 'Add Donation'}
          </Button>
        </FormProvider>
      </Card.Body>
    </Card>
  );
};

ADDonationDetails.propTypes = {
  onSubmit: PropTypes.func,
  data: PropTypes.object
};

export default ADDonationDetails;
