import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import BeneficiaryForm from './beneficiary-form/BeneficiaryForm';
import paths from 'routes/paths';
import LoadingContainer from 'components/common/LoadingContainer';
import { GET_SPONSORSHIP_COST } from './beneficiary-form/beneficiary-options-queries';
import { useAuthContext } from 'context/authContext';

const EDIT_BENEFICIARY = gql`
  mutation EditBeneficiary(
    $id: uuid
    $DOB: timestamp = ""
    $first_name: String = ""
    $last_name: String = ""
    $middle_name: String = ""
    $gender: String = ""
    $language: String = ""
    $address: String = ""
    $country: String = ""
    $city: String = ""
    $location_condition: String = ""
    $sponsorship_condition_id: uuid = ""
    $status: String = ""
    $health_notes: String = ""
    $inspiration: String = ""
    $cost: numeric
    $duration: jsonb = ""
    $sponsorship_type_id: uuid = ""
    $priorityId: uuid = ""
    $image: String = ""
    $organisation_id: uuid = ""
    $branch_id: uuid = ""
    $guardian: guardian_set_input
    $guardian_id: uuid = ""
  ) {
    update_guardian_by_pk(pk_columns: { id: $guardian_id }, _set: $guardian)
    update_orphans_by_pk(
      pk_columns: { id: $id }
      _set: {
        DOB: $DOB
        first_name: $first_name
        middle_name: $middle_name
        last_name: $last_name
        gender: $gender
        language: $language
        address: $address
        country: $country
        city: $city
        location_condition: $location_condition
        sponsorship_condition_id: $sponsorship_condition_id
        status: $status
        health_notes: $health_notes
        inspiration: $inspiration
        cost: $cost
        duration: $duration
        sponsorship_type_id: $sponsorship_type_id
        sponsorship_priority_id: $priorityId
        image: $image
        organisation_id: $organisation_id
        branch_id: $branch_id
        guardian_id: $guardian_id
        cost: $cost
      }
    ) {
      first_name
      middle_name
      gender
      country
      DOB
      gender
      sponsorship_condition {
        condition
      }
      sponsorship_priority {
        priority_type
      }
      cost
      status
      duration
      sponsorship_type {
        type
      }
      id
      last_name
      image
      location_condition_id
      address
    }
  }
`;

const GET_BENEFICIARY = gql`
  query GetBeneficiary($id: uuid!) {
    orphans_by_pk(id: $id) {
      first_name
      middle_name
      last_name
      gender
      language
      address
      city
      country
      location_condition
      inspiration
      cost
      status
      duration
      health_notes
      image
      DOB
      branch_id
      guardian_id
      id
      organisation_id
      sponsorship_condition_id
      sponsorship_priority_id
      sponsorship_type_id
      guardian {
        email
        first_name
        id
        last_name
        middle_name
        phone
        guardian_father_status {
          id
        }
        guardian_mother_status {
          id
        }
        guardian_relationShip {
          id
        }
      }
    }
  }
`;

function EditBeneficiary() {
  const navigate = useNavigate();
  const { organization } = useAuthContext();
  const { id } = useParams();
  const { data: beneficiaryData, loading: dataLoading } = useQuery(
    GET_BENEFICIARY,
    {
      variables: { id }
    }
  );

  const { data: costData, loading: isCostDataLoading } = useQuery(
    GET_SPONSORSHIP_COST,
    {
      fetchPolicy: 'network-only',
      variables: {
        organizationId: organization.id
      }
    }
  );

  const [editBeneficiary, { loading: editLoading }] = useMutation(
    EDIT_BENEFICIARY,
    {
      onCompleted: () => {
        toast.success('Beneficiary updated successfully');
        navigate(paths.beneficiaries);
      },
      onError: error => {
        toast.error(error.message);
        console.error(error);
      }
    }
  );
  const onSubmit = data => {
    const { guardian_id } = beneficiaryData.orphans_by_pk;
    editBeneficiary({
      variables: {
        ...data,
        id,
        guardian_id
      }
    });
  };

  return dataLoading || isCostDataLoading ? (
    <LoadingContainer />
  ) : (
    <BeneficiaryForm
      onSubmit={onSubmit}
      loading={editLoading}
      data={beneficiaryData?.orphans_by_pk}
      costData={costData?.sponsorship_cost}
      isEdit
    />
  );
}

export default EditBeneficiary;
