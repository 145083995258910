import { useLazyQuery } from '@apollo/client';
import classNames from 'classnames';
import { GET_DONORS } from 'components/add-donation/donation-form-components/ADSelectDonor';
import Avatar from 'components/common/Avatar';
import Logo from 'components/common/Logo';
import { navbarBreakPoint, topNavbarBreakpoint } from 'config';
import AppContext from 'context/Context';
import { useAuthContext } from 'context/authContext';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
// import NavbarTopDropDownMenus from './NavbarTopDropDownMenus';
import SearchBox from './SearchBox';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';

const NavbarTop = () => {
  const {
    config: { showBurgerMenu, navbarPosition, navbarCollapsed },
    setConfig
  } = useContext(AppContext);
  const { pathname } = useLocation();
  const isChat = pathname.includes('chat');
  const { organization } = useAuthContext();
  const [showDropShadow, setShowDropShadow] = useState(false);

  const [getDOnors, { data: donorsData }] = useLazyQuery(GET_DONORS);
  useEffect(() => {
    getDOnors({
      variables: {
        where: {
          organisation_id: { _eq: organization.id }
        }
      }
    });
  }, [organization, getDOnors]);
  const searchData = useMemo(() => {
    return donorsData?.donars.map(item => {
      return {
        id: item.id,
        url: '/donors/' + item.id + '/profile',
        catagories: 'suggestionMembers',
        icon: {
          img: (
            <Avatar
              src={''}
              rounded="circle"
              size="xl"
              name={item.first_name + ' ' + item.last_name}
            />
          ),
          size: 'l'
        },
        simple_id: item.simple_id,
        organisation_name: organization.organisation_name,
        title: item.first_name + ' ' + item.last_name,
        text:
          (item.email?.length === 1 ? item.email[0].email : '') +
          ' - ' +
          (item.phone?.length === 1 ? item.phone[0].phoneNumber : '') +
          ' - ' +
          (item.address?.length === 1 ? item.address[0].addressLine1 : '')
      };
    });
  }, [donorsData, organization]);
  // const searchData = [];
  const handleBurgerMenu = () => {
    (navbarPosition === 'top' || navbarPosition === 'double-top') &&
      setConfig('navbarCollapsed', !navbarCollapsed);
    (navbarPosition === 'vertical' || navbarPosition === 'combo') &&
      setConfig('showBurgerMenu', !showBurgerMenu);
  };

  const setDropShadow = () => {
    const el = document.documentElement;
    if (el.scrollTop > 0) {
      setShowDropShadow(true);
    } else {
      setShowDropShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', setDropShadow);
    return () => window.removeEventListener('scroll', setDropShadow);
  }, []);

  const burgerMenuRef = useRef();

  return (
    <>
      <Navbar
        className={classNames(
          'navbar-glass fs--1 navbar-top sticky-kit flex-wrap',
          {
            // 'navbar-glass-shadow': showDropShadow
            'navbar-glass-shadow': showDropShadow && !isChat
          }
        )}
        expand={
          navbarPosition === 'top' ||
          navbarPosition === 'combo' ||
          navbarPosition === 'double-top'
            ? topNavbarBreakpoint
            : true
        }
      >
        {searchData && (
          <NavbarTopElements
            navbarCollapsed={navbarCollapsed}
            navbarPosition={navbarPosition}
            handleBurgerMenu={handleBurgerMenu}
            burgerMenuRef={burgerMenuRef}
            searchData={searchData}
          />
        )}
      </Navbar>
      {/* {type !== 'organization' ? (
        isMediumScreen && <NavbarTopDropDownMenus width={width} />
      ) : (
        <></>
      )} */}
    </>
  );
};

export const NavbarTopElements = ({
  navbarPosition,
  handleBurgerMenu,
  searchData
}) => {
  const burgerMenuRef = useRef();
  return (
    <>
      <Navbar.Toggle
        ref={burgerMenuRef}
        className={classNames('toggle-icon-wrapper me-md-3 me-2', {
          'd-lg-none':
            navbarPosition === 'top' || navbarPosition === 'double-top',
          [`d-${navbarBreakPoint}-none`]:
            navbarPosition === 'vertical' || navbarPosition === 'combo'
        })}
        as="div"
      >
        <button
          className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
          onClick={handleBurgerMenu}
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </Navbar.Toggle>

      <Logo
        at="navbar-top"
        width={40}
        id="topLogo"
        textClass="d-none d-sm-block"
      />

      <Nav
        navbar
        className={`ms-auto d-none d-${topNavbarBreakpoint}-block`}
        as="ul"
      >
        <Nav.Item as="li">
          <SearchBox autoCompleteItem={searchData} />
        </Nav.Item>
      </Nav>

      <TopNavRightSideNavItem />
    </>
  );
};

NavbarTopElements.propTypes = {
  navbarPosition: PropTypes.string,
  handleBurgerMenu: PropTypes.func,
  navbarCollapsed: PropTypes.bool,
  searchData: PropTypes.array
};

NavbarTop.propTypes = {
  type: PropTypes.string,
  isMediumScreen: PropTypes.boolean,
  width: PropTypes.number
};
export default NavbarTop;
