import React, { useRef, useState } from 'react';
import { Card, Col, Form, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import DonorForm from 'components/donor/donor-form/DonorForm';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { EDIT_DONOR } from 'components/beneficiaries/donor/EditDonor';

const ProfileDonorInfo = ({ donorData }) => {
  const [showModal, setShowModal] = useState(false);

  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];

  const handleQuickUpdate = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const { id } = useParams();

  const [editDonar, { loading: editLoading }] = useMutation(EDIT_DONOR, {
    onCompleted: () => {
      toast.success('Donor updated successfully');
      handleCloseModal();
    },
    onError: error => {
      toast.error('Something went wrong! Please try again');
      console.log(error);
    }
  });

  const handleCreate = data => {
    editDonar({
      variables: { ...data, id }
    });
  };

  return (
    <>
      <Card className="mb-3">
        <FalconCardHeader
          title={<h4 className="fw-bold">Donor information</h4>}
          light={false}
          titleTag="div"
          className="pb-1"
          menuClassName="align-items-start"
          endEl={
            <IconButton
              icon="pen"
              variant="falcon-default"
              transform="shrink-3"
              className="fs--1 bg-transparent shadow-none"
              onClick={handleQuickUpdate}
            >
              Quick Update
            </IconButton>
          }
        />

        <Card.Body>
          <Row>
            <Col>
              <Flex>
                <div className="donor-info-title">
                  <p className="mb-2">Title</p>
                  <p className="mb-0">Name</p>
                </div>
                <div>
                  <p className="mb-2">{donorData?.donars_by_pk?.title}</p>
                  <p className="text-primary h6">
                    {donorData?.donars_by_pk?.first_name +
                      ' ' +
                      donorData?.donars_by_pk?.last_name}
                  </p>
                </div>
              </Flex>
              <hr />
              <Flex>
                <div className="donor-info-title">
                  <p>Address</p>
                </div>
                <div>
                  <p className="mb-2">
                    {donorData?.donars_by_pk?.address?.length
                      ? donorData?.donars_by_pk?.address[0].addressLine1 +
                        ', ' +
                        donorData?.donars_by_pk?.address[0].addressLine2
                      : ''}
                  </p>
                  <p className="mb-0">
                    {donorData?.donars_by_pk?.address?.length
                      ? donorData?.donars_by_pk?.address[0].city +
                        ', ' +
                        donorData?.donars_by_pk?.address[0].country +
                        ', ' +
                        donorData?.donars_by_pk?.address[0].postcode
                      : ''}
                  </p>
                </div>
              </Flex>
              <hr />
              <Flex>
                <div className="donor-info-title">
                  <p>Phone No.</p>
                </div>
                <div>
                  {donorData?.donars_by_pk?.phone?.length
                    ? donorData?.donars_by_pk?.phone.map((phone, index) => {
                        return (
                          <Flex key={index}>
                            <p className="mb-2">{'+' + phone.phoneNumber}</p>
                            <Form.Group
                              className="ms-3"
                              controlId={'phone-control-1'}
                            >
                              <Flex>
                                <Form.Check
                                  inline
                                  type="radio"
                                  name="phone"
                                  id={'phone-control-1'}
                                  value={'phone'}
                                  checked={phone.isPrimary}
                                  disabled
                                  className="me-2"
                                />
                                <Form.Label className="mb-0">
                                  Primary
                                </Form.Label>
                              </Flex>
                            </Form.Group>
                          </Flex>
                        );
                      })
                    : ''}
                </div>
              </Flex>
              <hr />
              <Flex>
                <div className="donor-info-title">
                  <p>E-Mail</p>
                </div>
                <div>
                  {donorData?.donars_by_pk?.email?.length
                    ? donorData?.donars_by_pk?.email.map((email, index) => {
                        return (
                          <Flex key={index}>
                            <p className="mb-2">{email.email}</p>
                            <Form.Group
                              className="ms-3"
                              controlId={'email-control-1'}
                            >
                              <Flex>
                                <Form.Check
                                  inline
                                  type="radio"
                                  name="email"
                                  id={'email-control-1'}
                                  value={'email'}
                                  checked={email.isPrimary}
                                  disabled
                                  className="me-2"
                                />
                                <Form.Label className="mb-0">
                                  Primary
                                </Form.Label>
                              </Flex>
                            </Form.Group>
                          </Flex>
                        );
                      })
                    : ''}
                </div>
              </Flex>
              <hr />
              {/* Donor Comment */}
              <Flex>
                <div className="donor-info-title">
                  <p className="mb-0">Comment</p>
                </div>
                <div>
                  <p className="mb-0">{donorData?.donars_by_pk?.comment}</p>
                </div>
              </Flex>
              <hr />
              {/* Donor Lead Source */}
              <Flex>
                <div className="donor-info-title">
                  <p className="mb-0">Lead Source</p>
                </div>
                <div>
                  <p className="mb-0">{donorData?.donars_by_pk?.lead_source}</p>
                </div>
              </Flex>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Update Donor Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DonorForm
            sectionRefs={sectionRefs}
            loading={editLoading}
            onSubmit={handleCreate}
            data={donorData?.donars_by_pk}
            isEdit
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

ProfileDonorInfo.propTypes = {
  donorData: PropTypes.object
};

export default ProfileDonorInfo;
