import { gql } from '@apollo/client';

const GET_DONORS = gql`
  query GetDonors($where: donars_bool_exp) {
    donars(where: $where, order_by: { created_at: desc }) {
      id
      simple_id
      first_name
      last_name
      phone
      email
      address
    }
  }
`;

const GET_DONATIONS = gql`
  query GetOrders($where: orders_bool_exp) {
    orders(where: $where, order_by: { created_at: desc }) {
      id
      payment_status
      donation_amount
      payment_method {
        payment_name
      }
    }
  }
`;

const GET_DONATION_ITEMS = gql`
  query GetDonationItems($where: order_details_bool_exp) {
    order_details(where: $where, order_by: { created_at: desc }) {
      id
      amount
      billing_condition
      donation_type {
        donation_type_name
      }
    }
  }
`;

export { GET_DONORS, GET_DONATIONS, GET_DONATION_ITEMS };
