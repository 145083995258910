import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';

import { useDonationContextProvider } from 'context/DonationContextProvider';

import Payment from 'components/add-donation/Payment';
import { INSERT_ORDER } from 'components/donations/add-donation';
import { formatMoney } from 'helpers/utils';
import { DONATION_STEPS } from './data';
import { FooterImages, PrivacyPolicy } from './DonationFooter';
import { DonationHeader } from './DonationHeader';
import { DonationItem } from './donor-information/donation-summary';

function DonationPaymentPage() {
  // const navigate = useNavigate();
  const { organizationId } = useParams();
  const [insertOrder] = useMutation(INSERT_ORDER, {
    onCompleted: data => {
      if (data.insertOrder.order_id) {
        // navigate('/failure');
        setDonationStep(DONATION_STEPS.THANKS);
      } else {
        toast.error(data.insertOrder.message);
      }
    },
    onError: error => {
      toast.error('Something went wrong');
      console.log(error);
    }
  });

  const {
    setDonationStep,
    basket,
    giftAid,
    donor,
    transactionFeeAmount,
    subtotal,
    totalAmount,
    transactionFee
  } = useDonationContextProvider();

  const onPay = async paymentMethodId => {
    try {
      let orderItems = basket.map(item => {
        const orderItem = {
          amount: item.count * item.donationAmount.value,
          billing_condition: item.paymentType,
          donation_type_id: item.donationType.id
        };
        if (item.packageId) {
          orderItem.package_id = item.packageId;
        }
        return orderItem;
      });

      orderItems = JSON.stringify(orderItems);

      let donorObject = {
        address: [
          {
            city: donor.city,
            county: donor.country,
            postcode: donor.postcode,
            isPrimary: true,
            addressLine1: donor.addressLine1,
            addressLine2: donor.addressLine2
          }
        ],
        last_name: donor.lastName,
        phone: [
          {
            type: 'personal',
            isPrimary: true,
            phoneNumber: donor.phoneNumber
          }
        ],
        organisation_id: organizationId,
        email: [{ email: donor.email, isPrimary: true, type: 'personal' }],
        primaryEmail: donor.email,
        first_name: donor.firstName,
        title: donor.title
      };
      donorObject = JSON.stringify(donorObject);

      await insertOrder({
        variables: {
          // email: donorEmail,
          organization_id: organizationId,
          // branch_id: selectedBranchId,
          order_details: orderItems,
          payment_method_id: paymentMethodId,
          subtotal: subtotal.toString(),
          transaction_fees: (transactionFee
            ? transactionFeeAmount
            : 0
          ).toString(),
          branch_id: '26fc76c5-67a5-408c-8360-9b90a3fd8626',
          donor_object: donorObject,
          // donor_object: JSON.stringify({
          //   email: donor.email,
          //   first_name: donor.firstName,
          //   last_name: donor.lastName,
          //   phone: donor.phoneNumber,
          //   address: donor.addressLine1,
          //   organisation_id: organizationId
          // }),
          donation_amount: totalAmount.toString(),
          gift_aid_eligible: giftAid,
          gift_aids: null
          // first_name: data.first_name,
          // last_name: data.last_name
        }
      });
    } catch (error) {
      toast.error('Something went wrong');
      console.log(error);
    }
  };

  return (
    <div>
      <DonationHeader />
      <div className="wr-donation-payment-container">
        <h2 className="text-uppercase wr-donation-body3 text-center mb-3">
          donation summary
        </h2>
        <div className="d-flex flex-column gap-3 mb-3">
          {basket.map(donation => (
            <DonationItem key={donation.id} donation={donation} />
          ))}
        </div>
        <header className="wr-donation-text-brand-secondary fw-bold d-flex justify-content-between align-items-center">
          <span>Subtotal</span>
          <span>{formatMoney(subtotal, '£')}</span>
        </header>
        <header className="wr-donation-text-brand-secondary fw-bold d-flex justify-content-between align-items-center">
          <span>Transaction Fee</span>
          <span>
            {formatMoney(transactionFee ? transactionFeeAmount : 0, '£')}
          </span>
        </header>
        <header className="wr-donation-text-brand-secondary fw-bold d-flex justify-content-between align-items-center">
          <span>Total</span>
          <span>{formatMoney(totalAmount, '£')}</span>
        </header>
        {/* divider */}
        <hr className="wr-donation-border-primary my-3" />
        <h2 className="text-uppercase wr-donation-body3 text-center mb-3">
          Payment Details
        </h2>
        <div className=" d-flex flex-column gap-2  mt-4">
          <div>
            <h2 className="wr-donation-payment-method-header">Card Details</h2>
            {/* <div className="d-flex gap-2 mb-4 justify-content-between justify-content-md-start">
              <IconButton
                className="p-2  rounded-3 fs--1 fw-semi-bold bg-wr-donation-primary"
                icon="credit-card"
                iconClassName="me-2 text-white"
              >
                Credit/Debit Card
              </IconButton>
            </div> */}
            <Payment onPay={onPay} />
          </div>
          <p className="wr-donation-text-grey-700">
            Give with confidence. Our secure payment system ensure your personal
            details are kept safe and secure
          </p>
        </div>
        <div className="my-5">
          <FooterImages />
        </div>
        <PrivacyPolicy />
      </div>
    </div>
  );
}

export { DonationPaymentPage };
