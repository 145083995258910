import { gql, useLazyQuery } from '@apollo/client';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { FormProvider, RHFControl, RHFSelect } from 'components/common/form';
import AuthContext from 'context/authContext';
import { genderOptions } from 'data/options/common';
import { getCountriesOptions } from 'data/options/countris-and-cities';
import { calculateAge, convertAgeToDOB } from 'helpers/utils';
import useBranchPermission from 'hooks/useBranchPermission';
import PropTypes from 'prop-types';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

/* -------------------------------------------------------------------------- */
export const GET_BENEFACTORS = gql`
  query GetBeneficiariesSearch($where: orphans_bool_exp!) {
    orphans(order_by: { created_at: desc }, where: $where) {
      first_name
      middle_name
      gender
      country
      DOB
      gender
      sponsorship_condition {
        condition
      }
      sponsorship_priority {
        priority_type
      }
      sponsorship_type {
        type
      }
      id
      simple_id
      last_name
      image
    }
  }
`;
/* -------------------------------------------------------------------------- */

const SelectBeneficiaryModal = ({ show, onHide, onSubmit, beneficiaryId }) => {
  const { organization } = useContext(AuthContext);

  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
  const [getBeneficiaries, { data, loading }] = useLazyQuery(GET_BENEFACTORS, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (beneficiaryId) {
      setSelectedBeneficiary(beneficiaryId);
    }
  }, [show, beneficiaryId]);

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: { country: '', name: '', gender: '', ageFrom: '', ageTo: '' }
  });
  const { handleSubmit, reset } = methods;

  useEffect(() => {
    reset({
      country: '',
      name: '',
      gender: '',
      ageFrom: '',
      ageTo: ''
    });
  }, [show, reset]);
  const { where } = useBranchPermission();

  const onSearchSubmit = ({ country, name, gender, ageFrom, ageTo }) => {
    if (country) {
      where.country = { _eq: country };
    }
    if (name) {
      where.first_name = { _ilike: `%${name}%` };
    }
    if (gender) {
      where.gender = { _eq: gender };
    }
    if (ageFrom) {
      const fromDate = convertAgeToDOB(ageFrom);
      where.DOB = { _lte: fromDate };
    }
    if (ageTo) {
      const toDate = convertAgeToDOB(ageTo);
      where.DOB = { ...where.DOB, _gte: toDate };
    } else {
      where.DOB = {};
    }

    getBeneficiaries({
      variables: { where }
    });
  };

  useEffect(() => {
    if (Object.keys(where).length > 0)
      getBeneficiaries({
        variables: { where }
      });
  }, [organization.id, show, where, getBeneficiaries]);

  const tableData = useMemo(
    () =>
      data?.orphans?.map(
        ({
          id,
          simple_id: simpleId,
          first_name,
          middle_name,
          last_name,
          DOB,
          country,
          sponsorship_priority,
          sponsorship_type,
          gender
        }) => ({
          id,
          simpleId,
          name: `${first_name} ${middle_name ?? ''} ${last_name}`,
          age: calculateAge(DOB),
          country,
          priority: sponsorship_priority?.priority_type,
          type: sponsorship_type?.type,
          gender
        })
      ),
    [data]
  );

  const columns = [
    {
      accessor: 'selection',
      Header: '',
      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <Form.Check>
            <Form.Check.Input
              type="checkbox"
              className="cursor-pointer"
              checked={selectedBeneficiary === id}
              readOnly
            />
          </Form.Check>
        );
      }
    },
    {
      accessor: 'simpleId',
      Header: 'Ref.',
      cellProps: {
        className: 'fw-semi-bold py-2'
      },
      Cell: rowData => {
        const { simpleId } = rowData.row.original;
        return `B${simpleId}`;
      }
    },
    {
      accessor: 'name',
      Header: 'Name',
      cellProps: {
        className: 'fw-medium py-2'
      },
      Cell: rowData => {
        const { name, image } = rowData.row.original;
        return (
          <Flex alignItems={'center'}>
            <Avatar src={image} name={name} />
            <p className="mb-0 ms-2">{name}</p>
          </Flex>
        );
      }
    },

    {
      accessor: 'age',
      Header: 'Age',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'country',
      Header: 'Location',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'gender',
      Header: 'Gender',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'type',
      Header: 'Type',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'priority',
      Header: 'Priority',
      cellProps: {
        className: 'py-2'
      }
    }
  ];
  const handleSelectBeneficiary = beneficiaryId => {
    if (selectedBeneficiary === beneficiaryId) {
      setSelectedBeneficiary(null);
      return;
    }
    setSelectedBeneficiary(beneficiaryId);
  };

  const onNext = () => {
    onHide();
    onSubmit(selectedBeneficiary);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title id="contained-modal-title-vcenter" as="h5">
          Select beneficiary
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="g-3">
          <Col lg={4}>
            <div className="border rounded-3 p-3">
              <p className="fw-medium">Search Beneficiary</p>
              <FormProvider
                methods={methods}
                onSubmit={handleSubmit(onSearchSubmit)}
              >
                <RHFControl name="name" label="Search" noOptional />
                <hr className="text-200 mb-2" />
                <RHFSelect
                  options={getCountriesOptions()}
                  noOptional
                  name="country"
                  label="Location"
                  className="mb-2"
                />
                <RHFSelect
                  options={genderOptions}
                  noOptional
                  name="gender"
                  label="Gender"
                  className="mb-2"
                />
                <Form.Group className="mb-3" controlId="Search">
                  <Form.Label>Age</Form.Label>

                  <Flex alignItems={'center'}>
                    <RHFControl name="ageFrom" placeholder="From" />
                    <span className="px-3">:</span>
                    <RHFControl name="ageTo" placeholder="To" />
                  </Flex>
                </Form.Group>
                <hr />
                <Button type="submit" className="w-100">
                  Search
                </Button>
              </FormProvider>
            </div>
          </Col>
          <Col lg={8} className="d-flex flex-column gap-2">
            <div className="border rounded-3 overflow-hidden">
              <AdvanceTableWrapper
                columns={columns}
                data={tableData ?? []}
                sortable
                pagination
                perPage={8}
              >
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                  loading={loading}
                  selectableRow
                  selectedRowId={selectedBeneficiary}
                  onSelectRow={handleSelectBeneficiary}
                />
                <div className="p-3">
                  <AdvanceTableFooter
                    rowCount={tableData?.length}
                    table
                    rowInfo
                    navButtons
                  />
                </div>
              </AdvanceTableWrapper>
            </div>
            <div className="d-flex justify-content-end mt-auto">
              <Button
                size="lg"
                className="px-7"
                disabled={!selectedBeneficiary}
                onClick={onNext}
              >
                Next
              </Button>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

SelectBeneficiaryModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  beneficiaryId: PropTypes.string
};

export default SelectBeneficiaryModal;
