import { useDonationContextProvider } from 'context/DonationContextProvider';

import { DONATION_STEPS } from './data';

import { Button, Offcanvas } from 'react-bootstrap';
import { GiftAidCard } from './GiftAidCard';
import { CoverTransactionFee } from './CoverTransactionFee';
import { formatMoney } from 'helpers/utils';

function GiftAidDrawer() {
  const {
    setDonationStep,
    isGiftAidDrawerOpen,
    setIsGiftAidDrawerOpen,
    totalAmount,
    subtotal,
    transactionFeeAmount,
    transactionFee
  } = useDonationContextProvider();

  const completeDonationHandler = () => {
    // setIsPackageDrawerOpen(false);
    // setIsCartDrawerOpen(false);
    setIsGiftAidDrawerOpen(false);
    setDonationStep(DONATION_STEPS.DONOR_INFORMATION);
  };

  return (
    <Offcanvas
      show={isGiftAidDrawerOpen}
      onHide={() => setIsGiftAidDrawerOpen(false)}
      placement="end"
      backdrop="static"
      className="bg-wr-donation-primary"
      style={{ width: '90%', maxWidth: 500 }}
    >
      <Offcanvas.Header>
        <Button
          className="bg-transparent shadow-none border-0 fs-2 p-0 text-danger"
          onClick={() => {
            setIsGiftAidDrawerOpen(false);
          }}
        >
          &times;
        </Button>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <h2 className="text-uppercase wr-donation-header2 text-white wr-donation-border-b-yellow pb-3 mb-4">
          Checkout
        </h2>
        <p className="wr-donation-body2 text-white mb-3">Help us further</p>
        <div className="mb-3">
          <GiftAidCard logoPosition="below_header" />
        </div>
        <CoverTransactionFee />
        <div className="wr-donation-summary-card d-flex flex-column gap-3">
          <header className="wr-donation-text-brand-secondary fw-bold d-flex justify-content-between align-items-center">
            <span>Subtotal</span>
            <span>{formatMoney(subtotal, '£')}</span>
          </header>
          <header className="wr-donation-text-brand-secondary fw-bold d-flex justify-content-between align-items-center">
            <span>Transaction Fee</span>
            <span>
              {formatMoney(transactionFee ? transactionFeeAmount : 0, '£')}
            </span>
          </header>
          <header className="wr-donation-text-brand-secondary fw-bold d-flex justify-content-between align-items-center">
            <span>Total</span>
            <span>{formatMoney(totalAmount, '£')}</span>
          </header>
          <Button
            className="border-0 bg-wr-donation-success-500 py-3"
            onClick={completeDonationHandler}
          >
            Proceed to Checkout &gt;
          </Button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

GiftAidDrawer.propTypes = {};

export { GiftAidDrawer };
