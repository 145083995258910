import { useState } from 'react';

import { useParams } from 'react-router-dom';

import { gql, useQuery } from '@apollo/client';

import { useDonationContextProvider } from 'context/DonationContextProvider';

import placeholderImage from 'assets/img/we-raise-images/image-placeholder.png';

import { Button, Card, Image } from 'react-bootstrap';
import LoadingContainer from 'components/common/LoadingContainer';
import { PackageDrawer } from './PackageDrawer';

export const GET_APPEALS_BY_ORGANIZATION = gql`
  query GetAppealsByOrganization($organizationId: uuid!) {
    urgent_appeals(
      where: {
        organisation_id: { _eq: $organizationId }
        is_display: { _eq: true }
      }
      order_by: { created_at: desc }
    ) {
      title
      images
      id
      package_id
      package {
        currency_id
        donation_amount
        donation_amounts
        donation_type
        donation_types
        location
        id
        simple_id
        organisation_id
        package_category
        package_description
        package_title
        payment_options
        redirect_url
        status
        transaction_fee
        branch_id
        branch {
          name
        }
      }
    }
  }
`;

function PackageList() {
  const [selectedPackage, setSelectedPackage] = useState();

  const { organizationId } = useParams();

  const { data, loading } = useQuery(GET_APPEALS_BY_ORGANIZATION, {
    variables: { organizationId: organizationId }
  });

  const { setIsPackageDrawerOpen } = useDonationContextProvider();

  if (loading) {
    return <LoadingContainer />;
  }

  const packageList = data.urgent_appeals;

  const donateNowHandler = packageItem => {
    setSelectedPackage(packageItem);
    setIsPackageDrawerOpen(true);
  };

  return (
    <>
      <div className="">
        <h2 className="text-center">Current Appeals</h2>
        <div
          style={{
            width: '50px',
            height: '2px',
            backgroundColor: 'red'
            // margin: '0 auto 30px'
          }}
          className="mx-auto mb-4 mt-3"
        />

        <div className="row">
          {packageList.map(item => (
            <div
              key={item.id}
              className="col-12 col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center mb-4"
            >
              <Card
                className="shadow-sm rounded-4 border-0"
                style={{ flex: '1 1 350px', maxWidth: '500px' }}
              >
                <Card.Body className="p-3">
                  <div className="text-center mb-2">
                    <Image
                      src={item?.images?.primary || placeholderImage}
                      alt={item.package_title}
                      width={200}
                      height={150}
                      style={{ objectFit: 'contain', maxWidth: '100%' }}
                    />
                  </div>

                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <span
                      className="fw-medium text-truncate"
                      style={{ maxWidth: '90%' }}
                      title={item.package_title}
                    >
                      {item.title}
                    </span>
                    {/* <span className="text-success fw-medium">
                      + £{item.package_price}
                    </span> */}
                  </div>

                  <Button
                    variant="warning"
                    className="w-100 text-dark fw-medium py-2"
                    style={{ backgroundColor: '#FFD75E', border: 'none' }}
                    onClick={() => donateNowHandler(item)}
                  >
                    Donate now
                  </Button>
                </Card.Body>
              </Card>
            </div>
          ))}
        </div>
      </div>
      {selectedPackage && (
        <PackageDrawer
          packageId={selectedPackage.package_id}
          packageName={selectedPackage.title}
          paymentTypeOptions={selectedPackage.package.payment_options || []}
          amountOptions={JSON.parse(
            selectedPackage.package.donation_amounts
          )?.filter(amount => amount.id !== 'custom')}
          hasCustomAmount={
            !!JSON.parse(selectedPackage.package.donation_amounts)?.find(
              amount => amount.id === 'custom'
            )
          }
          typeOptions={JSON.parse(selectedPackage.package.donation_types)}
        />
      )}
    </>
  );
}

export { PackageList };
