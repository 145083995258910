import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Card } from 'react-bootstrap';

import { toast } from 'react-toastify';

import AddressInputs from './AddressInputs';
import EmailInputs from './EmailInputs';
import NameInputs from './NameInputs';
import PhoneNumberInputs from './PhoneNumberInputs';
import ContactPreference from './ContactPreference';
import GiftAidEligibility from './GiftAidEligibility';
import LeadSource from './LeadSource';
import Flex from 'components/common/Flex';
import { FormProvider } from 'components/common/form';
import { donorScheme } from './donorScheme';
import LoadingButton from 'components/common/LoadingButton';
import AuthContext from 'context/authContext';
// import useScrollSpy from 'react-use-scrollspy';

const addressInitialValues = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  country: '',
  postcode: '',
  isPrimary: true
};

const phoneInitialValues = {
  phoneNumber: '',
  type: 'personal',
  isPrimary: true
};

const emailInitialValues = {
  email: '',
  type: 'personal',
  isPrimary: true
};

const DonorForm = ({ sectionRefs, loading, onSubmit, isEdit, data }) => {
  const { organization } = useContext(AuthContext);
  const [selectedDonorType, setSelectedDonorType] = useState('individual');
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      title: 'Mr',
      firstName: '',
      lastName: '',
      addresses: [addressInitialValues],
      phoneNumbers: [phoneInitialValues],
      emails: [emailInitialValues],
      contactPreference: '',
      giftAid: {
        eligibility: false,
        date: '',
        validFrom: {
          day: '',
          month: '',
          year: ''
        },
        declarationSource: '',
        notes: '',
        files: []
      },
      comment: '',
      lead_source: '',
      donor_category_id: '',
      type: 'individual'
    },
    resolver: yupResolver(donorScheme)
  });
  const { handleSubmit: RHFHandleSubmit, setValue, watch, reset } = methods;
  const values = watch();
  const { addresses, phoneNumbers, emails } = values;
  const handleSubmit = data => {
    if (!data.emails.find(email => email.isPrimary)) {
      toast.error('At least one primary email is required !');
      return;
    }
    // const addressJson = JSON.stringify(data.addresses);
    // const phoneJson = JSON.stringify(data.phoneNumbers);
    // const emailJson = JSON.stringify(data.emails);
    // const contactPreferenceJson = JSON.stringify(data.contactPreference);
    const variables = {
      address: data.addresses,
      primary_address: data.addresses.find(address => address.isPrimary)
        ?.addressLine1,
      contact_preferences: data.contactPreference,
      declaration_source: data.giftAid.declarationSource,
      email: data.emails,
      primary_email: data.emails.find(email => email.isPrimary)?.email,
      first_name: data.firstName,
      gift_aid_elgibility: data.giftAid.eligibility,
      gift_aid_file: '',
      last_name: data.lastName,
      comment: data.comment,
      lead_source: data.lead_source,
      phone: data.phoneNumbers,
      primary_phone: data.phoneNumbers.find(
        phoneNumber => phoneNumber.isPrimary
      )?.phoneNumber,
      title: data.title,
      // fixed values
      organisation_id: organization.id,
      remark: null,
      consented_date: null,
      consented_via: null,
      tax_vat_number: data.tax_vat_number,
      username: data.username,
      website: data.website,
      job_title: data.job_title,
      charity_registration_number: data.charity_registration_number,
      partner_name: data.partner_name,
      type: selectedDonorType,
      donor_category_id: data.donor_category_id
    };
    onSubmit(variables);
  };
  useEffect(() => {
    if (isEdit) {
      const {
        title,
        first_name,
        last_name,
        address,
        email,
        phone,
        tax_vat_number,
        username,
        website,
        is_donar,
        is_partner,
        is_sponsor,
        job_title,
        charity_registration_number,
        partner_name,
        comment,
        lead_source,
        type,
        donor_category_id
      } = data;

      // const addresses = JSON.parse(address);
      // const emails = JSON.parse(email);
      // const phoneNumbers = JSON.parse(phone);
      // const contactPreference = JSON.parse(contact_preferences);

      // const giftAid = {
      //   eligibility: gift_aid_elgibility,
      //   files: gift_aid_file
      // };
      const donorData = {
        title,
        firstName: first_name,
        lastName: last_name,
        addresses: address,
        emails: email,
        phoneNumbers: phone,
        tax_vat_number,
        username,
        website,
        is_donor: is_donar,
        is_partner,
        is_sponsor,
        job_title,
        charity_registration_number,
        partner_name,
        // contactPreference,  giftAid
        comment,
        lead_source,
        type,
        donor_category_id
      };
      reset(donorData);
    }
  }, [data, isEdit, setValue, reset]);
  const addNewAddress = () => {
    setValue('addresses', [...addresses, addressInitialValues]);
  };

  const deleteAddress = index => {
    setValue(
      'addresses',
      addresses.filter((_, i) => i !== index)
    );
  };

  const addNewPhone = () => {
    setValue('phoneNumbers', [...values.phoneNumbers, phoneInitialValues]);
  };

  const deletePhone = index => {
    setValue(
      'phoneNumbers',
      values.phoneNumbers.filter((_, i) => i !== index)
    );
  };

  const addNewEmail = () => {
    setValue('emails', [...values.emails, emailInitialValues]);
  };

  const deleteEmail = index => {
    setValue(
      'emails',
      values.emails.filter((_, i) => i !== index)
    );
  };
  // const activeSection = useScrollSpy({
  //   sectionElementRefs: sectionRefs,
  //   offsetPx: -150
  // });

  const submitText = isEdit ? 'Update' : 'Create';

  return (
    <FormProvider methods={methods} onSubmit={RHFHandleSubmit(handleSubmit)}>
      <div id={'name'} ref={sectionRefs[1]}>
        <NameInputs
          selectedDonorCategoryId={values.donor_category_id}
          setDonorCategoryId={catId => setValue('donor_category_id', catId)}
          selectedDonorType={selectedDonorType}
          setSelectedDonorType={setSelectedDonorType}
        />
      </div>
      <div id={'address'} ref={sectionRefs[2]}>
        <AddressInputs
          addNewAddress={addNewAddress}
          deleteAddress={deleteAddress}
          setValue={setValue}
          addresses={addresses}
          isEdit={isEdit}
        />
      </div>
      <div id={'phone_number'} ref={sectionRefs[3]}>
        <PhoneNumberInputs
          addNewPhone={addNewPhone}
          deletePhone={deletePhone}
          phoneNumbers={phoneNumbers}
        />
      </div>
      <div id={'email_address'} ref={sectionRefs[4]}>
        <EmailInputs
          addNewEmail={addNewEmail}
          deleteEmail={deleteEmail}
          emails={emails}
        />
      </div>
      <div
        id={'contact_preference'}
        ref={sectionRefs[5]}
        style={{ opacity: 0.4, display: 'none' }}
      >
        <ContactPreference />
      </div>
      <div
        id={'gift_aid'}
        ref={sectionRefs[6]}
        style={{ opacity: 0.4, display: 'none' }}
      >
        <GiftAidEligibility />
      </div>
      <div id={'source'} ref={sectionRefs[7]}>
        <LeadSource />
      </div>
      <Card>
        <Card.Body>
          <Flex justifyContent={'between'} alignItems={'center'}>
            <h5 className="mb-0 fs-0 fs-md-1">{submitText} Donor</h5>
            <LoadingButton loading={loading} type="submit">
              {submitText}
            </LoadingButton>
          </Flex>
        </Card.Body>
      </Card>
    </FormProvider>
  );
};
DonorForm.propTypes = {
  sectionRefs: PropTypes.array,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  isEdit: PropTypes.bool,
  data: PropTypes.object
};
export default DonorForm;
