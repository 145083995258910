import { useMemo, useState, useRef, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useDonationContextProvider } from 'context/DonationContextProvider';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import {
  PAYMENT_OPTION_MONTHLY,
  PAYMENT_OPTION_ONE_OFF
} from 'components/donations/donation-packages/package-form/PackageForm';

import { gql, useQuery } from '@apollo/client';
import { GET_DONATION_AMOUNTS, GET_DONATION_TYPE } from './queries';

import { FormProvider, RHFSelect } from 'components/common/form';
import { CustomDonationCheck } from './OptionButton';

export const GET_PACKAGES = gql`
  query GetPackages(
    $organizationId: uuid!
    $paymentOption: jsonb = ""
    $donationTypeId: jsonb = ""
    $locationIdList: jsonb = ""
  ) {
    packages(
      order_by: { created_at: desc }
      where: {
        organisation_id: { _eq: $organizationId }
        is_hidden: { _eq: false }
      }
    ) {
      currency_id
      donation_amount
      donation_type
      location
      id
      organisation_id
      package_category
      package_description
      package_title
      payment_options
      redirect_url
      status
      transaction_fee
      branch_id
    }
  }
`;

function QuickDonationForm() {
  // const [isMonthlySupportSectionVisible, setIsMonthlySupportSectionVisible] =
  //   useState(true);
  const [isCustomAmountSelected, setIsCustomAmountSelected] = useState(false);

  const customAmountInputRef = useRef(null);

  const { organizationId } = useParams();

  const { addDonationToBasket } = useDonationContextProvider();

  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      paymentType: PAYMENT_OPTION_ONE_OFF,
      isTenMonthlySupportChecked: false,
      donationType: null,
      packageId: null,
      donationAmount: {
        id: null,
        value: null
      }
    },
    resolver: yupResolver(quickDonationSchema)
  });
  const {
    watch,
    setValue,
    reset,
    formState: { errors },
    handleSubmit
  } = form;
  const paymentType = watch('paymentType');
  const donationAmount = watch('donationAmount');

  const { data: donationTypesData } = useQuery(GET_DONATION_TYPE, {
    variables: {
      organizationId
    }
  });

  const donationTypeOptions = useMemo(
    () =>
      donationTypesData?.donation_type?.map(({ id, donation_type_name }) => ({
        label: donation_type_name,
        value: id
      })),
    [donationTypesData]
  );

  const packages = useQuery(GET_PACKAGES, {
    variables: {
      organizationId
    }
  });

  const packageOptions = useMemo(() => {
    if (!packages.data) return [];
    // filter packages based on payment type
    const filteredPackages = packages.data.packages.filter(packageItem =>
      packageItem.payment_options.includes(paymentType)
    );
    return filteredPackages.map(({ id, package_title }) => ({
      label: package_title,
      value: id
    }));
  }, [packages.data, paymentType]);

  const { data: donationAmountsData } = useQuery(GET_DONATION_AMOUNTS, {
    variables: {
      organizationId
    }
  });
  const donationAmountOptions = donationAmountsData?.donation_amount
    ? [...donationAmountsData.donation_amount].sort(
        (a, b) => a.amount - b.amount
      )
    : [];

  const isOneOffPaymentTypeSelected = paymentType === PAYMENT_OPTION_ONE_OFF;
  const isMonthlyPaymentTypeSelected = paymentType === PAYMENT_OPTION_MONTHLY;

  const resetForm = () => {
    setValue('isTenMonthlySupportChecked', false);
    setIsCustomAmountSelected(false);
    reset();
  };

  const onAddDonation = data => {
    const donationTypeId = data.donationType;
    const packageName = packageOptions.find(
      option => option.value === data.packageId
    )?.label;
    addDonationToBasket({
      ...data,
      packageId: data.packageId,
      packageName,
      donationType: {
        id: donationTypeId,
        name: donationTypesData?.donation_type?.find(
          type => type.id === donationTypeId
        )?.donation_type_name
      },
      count: 1,
      id: uuid()
    });
    resetForm();
    toast.success('Donation added to the basket.');
  };

  useEffect(() => {
    if (isCustomAmountSelected) {
      customAmountInputRef.current?.focus();
    }
  }, [isCustomAmountSelected]);

  return (
    <FormProvider methods={form} onSubmit={handleSubmit(onAddDonation)}>
      <div className="wr-donation-shadow-1 rounded-3 p-2 d-flex flex-column gap-3">
        <div className="container p-0">
          <div className="row g-2">
            <div className="col">
              <CustomDonationCheck
                onClick={() => {
                  setValue('paymentType', PAYMENT_OPTION_ONE_OFF);
                  setValue('isTenMonthlySupportChecked', false);
                  // setIsMonthlySupportSectionVisible(true);
                }}
                checked={isOneOffPaymentTypeSelected}
              >
                Single Payment
              </CustomDonationCheck>
            </div>
            <div className="col">
              <CustomDonationCheck
                onClick={() => {
                  setValue('paymentType', PAYMENT_OPTION_MONTHLY);
                }}
                checked={isMonthlyPaymentTypeSelected}
              >
                Monthly Donation
              </CustomDonationCheck>
            </div>
          </div>
        </div>

        <div>
          <span className="wr-donation-body5 text-black d-inline-block mb-2 me-1">
            Donation Amount
          </span>
          {/* divider */}
          {/* <div
            style={{
              width: '50px',
              height: '2px',
              backgroundColor: 'red'
              // margin: '0 auto 30px'
            }}
            className="mx-auto mb-4 mt-3"
          /> */}
          <div className="container p-0">
            <div className="row g-2">
              {donationAmountOptions.map(amount => (
                <div className="col-md-2 col-6" key={amount.id}>
                  <CustomDonationCheck
                    onClick={() => {
                      setValue('donationAmount', {
                        id: amount.id,
                        value: amount.amount
                      });
                      setIsCustomAmountSelected(false);
                    }}
                    checked={donationAmount?.id === amount.id}
                  >
                    £{amount.amount}
                    {donationAmount?.id === amount.id && (
                      <Form.Check
                        type="checkbox"
                        inline
                        className="ms-2 wr-donation-payment-type-checkbox"
                        checked
                      />
                    )}
                  </CustomDonationCheck>
                </div>
              ))}
              <div className="col">
                <CustomDonationCheck
                  onClick={() => {
                    setIsCustomAmountSelected(true);
                    setValue('donationAmount', { id: null, value: null });
                  }}
                  checked={isCustomAmountSelected}
                >
                  Custom Amount
                  {isCustomAmountSelected && (
                    <Form.Check
                      type="checkbox"
                      inline
                      className="ms-2 wr-donation-payment-type-checkbox"
                      checked
                    />
                  )}
                </CustomDonationCheck>
              </div>
            </div>
          </div>
          {isCustomAmountSelected && (
            <Form.Control
              ref={customAmountInputRef}
              className="mt-3"
              style={{ height: '60px' }}
              required
              type="number"
              placeholder="Custom Amount"
              onChange={e => {
                setValue('donationAmount', {
                  id: 'other',
                  value: e.target.value
                });
              }}
            />
          )}
          {errors.donationAmount?.value && (
            <small className="text-danger">
              {errors.donationAmount.value.message}
            </small>
          )}
        </div>
        <div>
          <span className="wr-donation-body5 text-black d-inline-block mb-2 me-1">
            Donation Type
          </span>
          <RHFSelect
            name="donationType"
            // label={
            //   <span className="wr-donation-body6 text-black me-1">
            //     Donation Type
            //   </span>
            // }
            required
            style={{ height: '60px' }}
            groupClassName="h-60"
            options={donationTypeOptions}
          />
        </div>
        <div>
          <span className="wr-donation-body5 text-black d-inline-block mb-2 me-1">
            Project/Campaign
          </span>
          <RHFSelect
            name="packageId"
            // label={
            //   <span className="wr-donation-body6 text-black me-1">
            //     Donation Type
            //   </span>
            // }
            required
            style={{ height: '60px' }}
            groupClassName="h-60"
            options={packageOptions}
          />
        </div>
        <Button
          className="align-self-center px-6 py-2 fw-bold text-white bg-wr-donation-primary border-0 w-100 w-md-auto"
          type="submit"
        >
          Add Donation
        </Button>
      </div>
    </FormProvider>
  );
}

const quickDonationSchema = Yup.object().shape({
  paymentType: Yup.string().required('Payment type is required'),

  isTenMonthlySupportChecked: Yup.boolean(),

  donationType: Yup.string().required('Donation type is required'),

  packageId: Yup.string().required('Project/Campaign is required'),

  donationAmount: Yup.object().shape({
    id: Yup.string().nullable(),
    value: Yup.number().nullable().required('Donation amount value is required')
  })
});

export { QuickDonationForm };
