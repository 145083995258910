import dayjs from 'dayjs';

import { Button } from 'react-bootstrap';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';

function SponsorshipDonation() {
  const tableColumns = [
    {
      accessor: 'donation_id',
      Header: 'Donation ID',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'donor_name',
      Header: 'Donor Name',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'donation_date',
      Header: 'Donation Date',
      cellProps: {
        className: 'fw-medium py-2'
      },
      Cell: rowData => {
        const { donation_date } = rowData.row.original;
        return dayjs(donation_date).format('DD/MM/YYYY, HH:mm');
      }
    },
    {
      accessor: 'donation_type',
      Header: 'Donation Type',
      cellProps: {
        className: 'fw-medium py-2'
      }
    },
    {
      accessor: 'payment_frequency',
      Header: 'Payment Frequency',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'donation_amount',
      Header: 'Donation Amount',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'suggested_beneficiary',
      Header: 'Suggested Beneficiary',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'priority',
      Header: 'Priority',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'actions',
      Header: '',
      cellProps: {
        className: 'py-2'
      },
      Cell: () => {
        return (
          <div className="d-flex gap-2">
            <Button variant="alert">Edit</Button>
            <Button variant="success">Approve</Button>
          </div>
        );
      }
    }
  ];

  return (
    <div>
      <CustomAdvanceTable
        data={[]}
        columns={tableColumns}
        loading={false}
        title="Sponsorship Donation Review"
      />
    </div>
  );
}

export default SponsorshipDonation;
