import PropTypes from 'prop-types';
import { createContext, useContext, useMemo, useState } from 'react';

import { DONATION_STEPS } from '../components/Home/data';
import { useParams } from 'react-router-dom';

export const DonationContext = createContext({
  donationPackages: [],
  setDonationPackages: () => {},
  urgentAppeal: [],
  setUrgentAppeal: () => {},
  giftAid: false,
  setGiftAid: () => {},
  transactionFee: false,
  setTransactionFee: () => {},
  transactionFeeAmount: 0,
  setTransactionFeeAmount: () => {},
  donor: {},
  setDonor: () => {},
  subtotal: 0,
  totalAmount: 0
});

export function DonationContextProvider({ children }) {
  const [donationStep, setDonationStep] = useState(
    DONATION_STEPS.BASKET_CREATION
  );
  const [basket, setBasket] = useState([]);
  const [isPackageDrawerOpen, setIsPackageDrawerOpen] = useState(false);
  const [isCartDrawerOpen, setIsCartDrawerOpen] = useState(false);
  const [isGiftAidDrawerOpen, setIsGiftAidDrawerOpen] = useState(false);
  const [donationPackages, setDonationPackages] = useState([]);
  const [urgentAppeal, setUrgentAppeal] = useState([]);
  const [giftAid, setGiftAid] = useState(false);
  const [transactionFee, setTransactionFee] = useState(false);
  const [donor, setDonor] = useState();
  const [transactionFeeAmount, setTransactionFeeAmount] = useState(0);

  const { organizationId } = useParams();

  const addDonationToBasket = donation => {
    setBasket(prevState => [...prevState, donation]);
  };
  const increaseDonationCount = (donationId, increaseBy) => {
    setBasket(prevState =>
      prevState.map(item => {
        if (item.id === donationId) {
          return { ...item, count: item.count + increaseBy };
        }
        return item;
      })
    );
  };
  const decreaseDonationCount = (donationId, decreaseBy) => {
    const basketItem = basket.find(item => item.id === donationId);
    const newCount = basketItem.count - decreaseBy;

    if (newCount === 0) {
      setBasket(prevState => prevState.filter(item => item.id !== donationId));
    } else {
      setBasket(prevState =>
        prevState.map(item => {
          if (item.id === donationId) {
            return { ...item, count: newCount };
          }
          return item;
        })
      );
    }
  };

  const subtotal = useMemo(
    () =>
      basket.reduce(
        (acc, curr) => acc + curr.count * curr.donationAmount.value,
        0
      ),
    [basket]
  );

  const totalAmount = useMemo(
    () => subtotal + (transactionFee ? transactionFeeAmount : 0),
    [subtotal, transactionFee, transactionFeeAmount]
  );

  return (
    <DonationContext.Provider
      value={{
        organizationId,
        donationStep,
        basket,
        setBasket,
        addDonationToBasket,
        increaseDonationCount,
        decreaseDonationCount,
        setDonationStep,
        isPackageDrawerOpen,
        setIsPackageDrawerOpen,
        isCartDrawerOpen,
        setIsCartDrawerOpen,
        isGiftAidDrawerOpen,
        setIsGiftAidDrawerOpen,
        donationPackages,
        setDonationPackages,
        urgentAppeal,
        setUrgentAppeal,
        giftAid,
        setGiftAid,
        transactionFee,
        setTransactionFee,
        transactionFeeAmount,
        setTransactionFeeAmount,
        donor,
        setDonor,
        subtotal,
        totalAmount
      }}
    >
      {children}
    </DonationContext.Provider>
  );
}

DonationContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useDonationContextProvider = () => {
  const context = useContext(DonationContext);
  if (context === undefined) {
    throw new Error('Context Problem.');
  }
  return context;
};

export default DonationContext;
