import { useState, useContext } from 'react';
import { Routes, Navigate, Route, useNavigate } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { getRelativePath } from 'helpers/utils';
import DonationConfig from './DonationConfig';
import paths from 'routes/paths';
import Payment from './Payment';
import AuthContext from 'context/authContext';
import { GIFT_AID_STATUS_UNCLAIMED } from 'components/gift-aid/unclaimed-transactions';
import { GET_DONORS } from './donation-form-components/ADSelectDonor';

export const INSERT_ORDER = gql`
  mutation InsertOrder(
    $email: String!
    # $organization_id: uuid!
    $branch_id: uuid!
    $order_details: String!
    $payment_method_id: String!
    $subtotal: float8!
    $transaction_fees: float8!
    $donation_amount: float8!
    $gift_aid_eligible: Boolean!
    $gift_aids: gift_aid_arr_rel_insert_input
    # $first_name: String!
    # $last_name: String!
    $donor_object: String = null
    $donor_id: String = null
  ) {
    insertOrder(
      email: $email
      # organization_id: $organization_id
      branch_id: $branch_id
      order_details: $order_details
      payment_method_id: $payment_method_id
      subtotal: $subtotal
      transaction_fees: $transaction_fees
      donation_amount: $donation_amount
      gift_aid_eligible: $gift_aid_eligible
      gift_aids: $gift_aids
      first_name: $first_name
      last_name: $last_name
      donor_object: $donor_object
      donor_id: $donor_id
    ) {
      message
      failed_payments
    }
  }
`;

function CreateDonation() {
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const { organization, selectedBranchId } = useContext(AuthContext);
  const [insertOrder, { loading }] = useMutation(INSERT_ORDER, {
    onCompleted: response => {
      if (response.insertOrder.message === 'success') {
        navigate('/success');
      } else {
        navigate('/failure');
      }
    },
    onError: error => {
      toast.error('Something went wrong');
      console.log(error);
    }
  });

  const { data: donorsData } = useQuery(GET_DONORS, {
    variables: {
      where: {
        organisation_id: { _eq: organization.id }
      }
    }
  });

  const onSubmitDonationConfig = data => {
    setData(data);

    const {
      donorId,
      donationItems,
      subtotal,
      transactionFees,
      total,
      settings
    } = data;
    // donorsData.donars.forEach(element => {
    //   if (element.id === donorId) {
    //     element.email.forEach(email => {
    //       if (email.isPrimary === true) {
    //         donorEmail = email.email;
    //       }
    //     });
    //   }
    // });

    const giftAidData = {
      data: {
        amount: total * 0.25,
        donar_id: donorId,
        status: GIFT_AID_STATUS_UNCLAIMED
      }
    };
    setData({
      donorId,
      donationItems,
      subtotal,
      transactionFees,
      total,
      settings,
      organization: organization.id,
      donorsData,
      giftAidData
      // donorEmail
    });
  };

  const onPay = async paymentMethodId => {
    try {
      const {
        donorId,
        donationItems,
        subtotal,
        transactionFees,
        total,
        settings,
        giftAidData
      } = data;

      const orderDetails = JSON.stringify(
        donationItems.map(({ amount, values, type, packageId }) => ({
          amount,
          billing_condition: values.billingCondition,
          donation_type_id: type,
          package_id: packageId
        }))
      );

      await insertOrder({
        variables: {
          // email: donorEmail,
          donor_id: donorId,
          // organization_id: organization.id,
          branch_id: selectedBranchId,
          order_details: orderDetails,
          payment_method_id: paymentMethodId,
          subtotal,
          transaction_fees: settings.coverTransactionFee ? transactionFees : 0,
          donation_amount: total,
          gift_aid_eligible: settings.giftAid,
          gift_aids: settings.giftAid ? giftAidData : null,
          first_name: data.first_name,
          last_name: data.last_name
        }
      });
    } catch (error) {
      toast.error('Something went wrong');
      console.log(error);
    }
  };

  return (
    <Routes>
      <Route
        path={getRelativePath(paths.configNewDonation, paths.addNewDonation)}
        element={
          <DonationConfig onSubmit={onSubmitDonationConfig} loading={loading} />
        }
      />
      <Route
        path={getRelativePath(paths.payNewDonation, paths.addNewDonation)}
        element={<Payment onPay={onPay} loading={loading} data={data} />}
      />
      <Route
        path="*"
        element={
          <Navigate
            to={getRelativePath(paths.configNewDonation, paths.addNewDonation)}
            replace
          />
        }
      />
    </Routes>
  );
}

export default CreateDonation;
