import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { useLazyQuery } from '@apollo/client';
import {
  GET_ORGANIZATION_ORDERS,
  organizationOrdersTableColumns
} from 'components/dashboards';
import useBranchPermission from 'hooks/useBranchPermission';

import paths from 'routes/paths';

import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import DonationsFilterForm from './DonationsFilterForm';

function DonationsPage() {
  const { where } = useBranchPermission();
  const [getOrders, { data: orderData, loading: ordersLoading }] = useLazyQuery(
    GET_ORGANIZATION_ORDERS
  );

  const tableData = useMemo(() => {
    return orderData?.orders.map(order => {
      // setTotalDonation(totalDonation + order.donation_amount ?? 0);
      let primaryEmail = '';
      if (order.donar.email?.length) {
        primaryEmail =
          order.donar.email?.find(email => email.isPrimary)?.email ?? '';
      }
      return {
        name: (
          <Link to={paths.donorDetails.replace(':id', order.donar.id)}>
            {order.donar.first_name} {order.donar.last_name}
          </Link>
        ),
        email: primaryEmail,
        created_at: order.created_at,
        order_details: (
          <ul className="list-unstyled mb-0">
            {order.order_details.map((order, index) => (
              <li key={index}>
                {order.package?.package_title || 'Quick Donation'} |{' '}
                {order.billing_condition} |{' '}
                {order.donation_type?.donation_type_name}
              </li>
            ))}
          </ul>
        ),
        // description:
        //   order.order_details.length !== 0
        //     ? order.order_details[0]?.package?.package_title
        //     : '',
        payment_method: order.payment_method?.payment_name,
        payment_status: order.payment_status,
        score: 0,
        variant: 'success',
        amount: `$${order.donation_amount}`,
        id: order.id
      };
    });
  }, [orderData]);

  const onFilterClick = data => {
    getOrders({
      variables: {
        where: {
          ...where,
          ...(data.type && {
            order_details: { donation_type: { _eq: data.type } }
          }),
          ...(data.status && {
            payment_status: { _eq: data.status }
          }),
          ...((data.date.from || data.date.to) && {
            created_at: {
              ...(data.date?.from && { _gte: data.date.from }),
              ...(data.date?.to && { _lte: data.date.to })
            }
          })
        }
      },
      fetchPolicy: 'network-only'
    });
  };

  useEffect(() => {
    getOrders({ variables: { where } });
  }, [getOrders, where]);

  return (
    <div>
      <div className="card shadow-none border mb-4">
        <div className="card-body">
          <DonationsFilterForm onFilter={onFilterClick} />
        </div>
      </div>
      <div className="mb-2 d-flex justify-content-end">
        <Link to={paths.addDonation}>
          <Button>Add Donation</Button>
        </Link>
      </div>

      <CustomAdvanceTable
        data={tableData ?? []}
        columns={organizationOrdersTableColumns}
        loading={ordersLoading}
        title="Donations"
      />
    </div>
  );
}

export default DonationsPage;
