import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';

const ADSelectedAppeals = ({
  items,
  transactionFee,
  subtotal,
  total,
  onEdit,
  onRemove
}) => {
  // const [showDeleteModal, setShowDeleteModal] = useState(false);
  // const [selectedAppealIndex, setSelectedAppealIndex] = useState();
  // const handleDeleteClick = (index) => {
  //   setShowDeleteModal(true);
  //   setSelectedAppealIndex(index);
  // };
  return (
    <Card className="mb-3">
      {/* <ConfirmationModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={onDelete}
        title="Delete Appeal"
        body="Are you sure you want to delete this appeal?"
      /> */}

      <Card.Header className="pb-0">
        <Card.Title className="fw-medium">Selected Appeals</Card.Title>
      </Card.Header>
      <Card.Body>
        {items.length === 0 && (
          <h5 className="text-center text-500">No appeals selected</h5>
        )}
        {items?.map(({ amount, type, name, locations }, index) => (
          <Flex
            key={index}
            justifyContent={'between'}
            className="border rounded-3 p-3 mb-3"
          >
            <div>
              <p className="mb-1 fs-0 fw-medium">
                {type.name} | {name}
              </p>
              <p className="mb-1 fs-0 fw-medium">{locations.join(', ')}</p>
              <p className="mb-0 fw-medium">
                Donation Amount :{' '}
                <span className="text-primary">${amount}</span>
              </p>
            </div>
            <Flex>
              <IconButton
                icon={'pen'}
                iconClassName="me-1"
                variant="link"
                className="p-0"
                onClick={() => onEdit(index)}
              >
                <span className="d-none d-md-inline-block">Edit</span>
              </IconButton>
              <IconButton
                icon={'trash'}
                iconClassName="text-danger"
                variant="link"
                className="p-0 ms-3"
                onClick={() => onRemove(index)}
              />
            </Flex>
          </Flex>
        ))}
        <hr />
        <Flex justifyContent={'between'}>
          <div>
            <p className="fw-medium mb-2">subtotal</p>
            <p className="fw-medium mb-2">Transaction Fees</p>
            <p className="fs-0 mb-0 text-1000 fw-semi-bold">Total amount</p>
          </div>
          <div>
            <p className="fw-medium mb-2 text-end">${subtotal.toFixed(2)}</p>
            <p className="fw-medium mb-2 text-end">
              ${transactionFee.toFixed(2)}
            </p>
            <p className="fs-0 mb-0 text-1000 fw-semi-bold text-end">
              ${total.toFixed(2)}
            </p>
          </div>
        </Flex>
      </Card.Body>
    </Card>
  );
};

ADSelectedAppeals.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      type: PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
      locations: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string
    })
  ),
  transactionFee: PropTypes.number,
  subtotal: PropTypes.number,
  total: PropTypes.number,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func
};

ADSelectedAppeals.defaultProps = {
  items: [],
  transactionFee: 0,
  total: 0
};

export default ADSelectedAppeals;
