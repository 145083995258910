import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';
import React from 'react';
import PaymentForm from './PaymentForm';

const stripePromise = loadStripe(
  'pk_test_51PGeUaFzXImQNma54cp0zjPaeEa1J60UNM6GO9IHTXwWjbUsLL97eS7lbnMde9qfoZd5lfkw3CTGbVYmpzKrtSeg00QNC9dzhr'
);

const Payment = ({ onPay }) => (
  <Elements stripe={stripePromise} options={{ appearance: { theme: 'flat' } }}>
    <PaymentForm onPay={onPay} />
  </Elements>
);

Payment.propTypes = {
  onPay: PropTypes.func.isRequired
};

export default Payment;
