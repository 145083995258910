import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import paths from 'routes/paths';
import classNames from 'classnames';
import { parseRouteParams } from 'helpers/utils';

import useBulkSelect from 'hooks/useBulkSelect';
import usePagination from 'hooks/usePagination';

import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';

import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import SponsoredHeader from './SponsoredHeader';
import SponsoredCardLayout from './SponsoredCardLayout';
import DonorSponsorshipModal from 'components/donor-details/modals/DonorSponsorshipModal';
import SelectBeneficiaryModal from 'components/beneficiaries/sponsorships/sponsorship-form/SelectBeneficiaryModal';

const SponsorshipList = props => {
  const { data, initialLayout = 'listView', title = 'List' } = props;

  const [layout, setLayout] = useState(initialLayout);
  const [isDonorSponsorshipModalOpen, setIsDonorSponsorshipModalOpen] =
    useState(false);
  const [isBeneficiaryModalOpen, setIsBeneficiaryModalOpen] = useState(false);

  const [primaryTickets, setPrimaryTickets] = useState(data);
  const [pageSize] = useState(10);

  const {
    paginationState: {
      data: paginatedTicket,
      totalItems,
      canNextPage,
      currentPage,
      canPreviousPage,
      paginationArray
    },
    nextPage,
    prevPage,
    goToPage
  } = usePagination(primaryTickets, pageSize);

  const beneficiaryIdList = data?.map(beneficiary => beneficiary.id);
  const { selectedItems, isSelectedItem, toggleSelectedItem } =
    useBulkSelect(beneficiaryIdList);

  const navigate = useNavigate();

  const handleTicketsSearch = text => {
    const filteredTickets = data.filter(ticket =>
      ticket.name.toLowerCase().includes(text.toLowerCase())
    );
    setPrimaryTickets(filteredTickets);
  };

  const columns = [
    {
      accessor: 'simpleId',
      Header: 'ID',
      cellProps: {
        className: 'py-2 text-600'
      }
    },
    {
      accessor: 'name',
      Header: 'Name',
      headerProps: { className: 'ps-2', style: { height: '46px' } },
      cellProps: {
        className: 'py-2 white-space-nowrap pe-3 pe-xxl-4 ps-2'
      },
      Cell: rowData => {
        const { name, image } = rowData.row.original;
        return (
          <Flex alignItems="center" className="position-relative py-1">
            <Avatar src={image} name={name} size="xl" className="me-2" />
            <h6 className="mb-0">
              <Button
                variant="link"
                onClick={() => setIsDonorSponsorshipModalOpen(true)}
                className="stretched-link text-900 p-0 fs--1 hover-primary"
              >
                {name}
              </Button>
            </h6>
          </Flex>
        );
      }
    },
    {
      accessor: 'age',
      Header: 'Age',
      cellProps: {
        className: 'py-2 fw-semi-bold text-600',
        style: { minWidth: '70px' }
      }
    },
    {
      accessor: 'location',
      Header: 'Location',
      cellProps: {
        className: 'py-2 fw-semi-bold text-600',
        style: { minWidth: '150px' }
      }
    },
    {
      accessor: 'gender',
      Header: 'Gender',
      cellProps: {
        className: 'py-2 fw-semi-bold text-600',
        style: { minWidth: '80px' }
      }
    },
    {
      accessor: 'sponsor',
      Header: 'Sponsor',
      cellProps: {
        className: 'py-2 text-900',
        style: { minWidth: '100px' }
      }
    },
    {
      accessor: 'renewal',
      Header: 'Renewal',
      cellProps: {
        className: 'py-2 fw-semi-bold text-600',
        style: { minWidth: '100px' }
      }
    },
    {
      accessor: 'duration',
      Header: 'Duration',
      cellProps: {
        className: 'py-2 fw-semi-bold text-600',
        style: { minWidth: '90px' }
      }
    },
    {
      accessor: 'type',
      Header: 'Type',
      cellProps: {
        className: 'py-2 text-900',
        style: { minWidth: '100px' }
      }
    }
    // {
    //   accessor: 'id_d',
    //   DonationHeader: '',
    //   headerProps: {
    //     className: 'text-end'
    //   },
    //   cellProps: {
    //     className: 'text-end fw-medium py-2'
    //   },
    //   disableSortBy: true,
    //   Cell: rowData => {
    //     const { id } = rowData.row;
    //     return (
    //       <TableRowAction
    //         onCLickEdit={() => console.log(id)}
    //         onCLickDelete={() => console.log(id)}
    //       />
    //     );
    //   }
    // }
  ];

  const onSelectBeneficiarySubmit = beneficiaryId => {
    navigate(
      parseRouteParams(
        paths.createNewSponsorship,
        {},
        {
          beneficiaryId: beneficiaryId
        }
      )
    );
  };

  useEffect(() => {
    setPrimaryTickets(data);
  }, [data]);

  return (
    <>
      <SelectBeneficiaryModal
        onHide={() => setIsBeneficiaryModalOpen(false)}
        show={isBeneficiaryModalOpen}
        onSubmit={onSelectBeneficiarySubmit}
      />

      <AdvanceTableWrapper
        columns={columns}
        data={paginatedTicket?.length ? paginatedTicket : primaryTickets}
        selectionColumnWidth={52}
        sortable
        // selection
      >
        <>
          {layout === 'listView' ? (
            <Card>
              <Header
                title={title}
                layout={layout}
                onLayoutChange={setLayout}
                onSearch={handleTicketsSearch}
                selectedItems={selectedItems}
                totalItems={totalItems}
                onSponsorClick={() => setIsBeneficiaryModalOpen(true)}
              />
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  headerClassName="bg-light text-800 align-middle"
                  rowClassName="btn-reveal-trigger align-middle"
                  tableProps={{
                    size: 'sm',
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
              </Card.Body>

              <Card.Footer className="d-flex justify-content-center">
                <Footer
                  canPreviousPage={canPreviousPage}
                  prevPage={prevPage}
                  canNextPage={canNextPage}
                  nextPage={nextPage}
                  currentPage={currentPage}
                  goToPage={goToPage}
                  paginationArray={paginationArray}
                />
              </Card.Footer>
            </Card>
          ) : (
            <>
              <Card className="mb-3">
                <Header
                  title={title}
                  layout={layout}
                  onLayoutChange={setLayout}
                  onSearch={handleTicketsSearch}
                  selectedItems={selectedItems}
                  totalItems={totalItems}
                  onSponsorClick={() => setIsBeneficiaryModalOpen(true)}
                />
              </Card>

              <SponsoredCardLayout
                data={paginatedTicket}
                isSelectedItem={isSelectedItem}
                toggleSelectedItem={toggleSelectedItem}
              />
              <Flex justifyContent={'center'} className={'mt-3'}>
                <Footer
                  canPreviousPage={canPreviousPage}
                  prevPage={prevPage}
                  canNextPage={nextPage}
                  nextPage={nextPage}
                  currentPage={currentPage}
                  goToPage={goToPage}
                  paginationArray={paginationArray}
                />
              </Flex>
            </>
          )}
        </>
      </AdvanceTableWrapper>

      {isDonorSponsorshipModalOpen && (
        <DonorSponsorshipModal
          modalShow={isDonorSponsorshipModalOpen}
          setModalShow={setIsDonorSponsorshipModalOpen}
        />
      )}
    </>
  );
};

function Header(props) {
  const {
    title,
    layout,
    onLayoutChange,
    selectedItems,
    totalItems,
    onSearch,
    onSponsorClick
  } = props;

  return (
    <Card.Header className="border-bottom border-200 px-0">
      <SponsoredHeader
        title={title}
        layout={layout}
        setLayout={onLayoutChange}
        selectedItems={selectedItems}
        totalItems={totalItems}
        handleTicketsSearch={onSearch}
        sponsorLick={onSponsorClick}
        selectedRowIds={selectedItems}
      />
    </Card.Header>
  );
}

function Footer(props) {
  const {
    canPreviousPage,
    prevPage,
    canNextPage,
    nextPage,
    paginationArray,
    currentPage,
    goToPage
  } = props;

  return (
    <>
      <div>
        <Button
          variant="falcon-default"
          size="sm"
          className={classNames('me-2', {
            disabled: !canPreviousPage
          })}
          onClick={prevPage}
        >
          <FontAwesomeIcon icon="chevron-left" />
        </Button>
      </div>
      <ul className="pagination mb-0">
        {paginationArray.map(page => (
          <li
            key={page}
            className={classNames({ active: currentPage === page })}
          >
            <Button
              size="sm"
              variant="falcon-default"
              className="page me-2"
              onClick={() => goToPage(page)}
            >
              {page}
            </Button>
          </li>
        ))}
      </ul>
      <div>
        <Button
          variant="falcon-default"
          size="sm"
          className={classNames({ disabled: !canNextPage })}
          onClick={nextPage}
        >
          <FontAwesomeIcon icon="chevron-right" />
        </Button>
      </div>
    </>
  );
}

Header.propTypes = {
  title: PropTypes.string,
  layout: PropTypes.string,
  onLayoutChange: PropTypes.func,
  selectedItems: PropTypes.array,
  totalItems: PropTypes.number,
  onSearch: PropTypes.func,
  onSponsorClick: PropTypes.func
};

Footer.propTypes = {
  canPreviousPage: PropTypes.bool,
  prevPage: PropTypes.func,
  canNextPage: PropTypes.bool,
  nextPage: PropTypes.func,
  paginationArray: PropTypes.array,
  currentPage: PropTypes.number,
  goToPage: PropTypes.func
};

SponsorshipList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  initialLayout: PropTypes.string,
  title: PropTypes.string
};

export default SponsorshipList;
