import PropTypes from 'prop-types';

import { Card, Col, Form, Image, Row } from 'react-bootstrap';

import classNames from 'classnames';

import { useDonationContextProvider } from 'context/DonationContextProvider';

import img from 'assets/img/we-raise-images/front-end/gift-aid-logo_png-1.png';
import { formatMoney } from 'helpers/utils';

function GiftAidCard(props) {
  const { logoPosition } = props;

  const { giftAid, setGiftAid, totalAmount } = useDonationContextProvider();
  const amountWithGiftAid = totalAmount * 1.25;

  return (
    <Card className="wr-donation-shadow p-4 wr-donation-text-grey-1000">
      <Card.Header className="p-0 mb-3 text-uppercase wr-donation-header3">
        gift aid your donation
      </Card.Header>
      <Card.Body className="p-0">
        <Row className="g-3">
          <Col
            md={logoPosition === 'right' ? 8 : 12}
            className={classNames('order-1', {
              'order-md-0': logoPosition === 'right'
            })}
          >
            <p className="wr-donation-subtitle1 mb-0">
              Add 25% more to your donation at no cost to you.
            </p>
            <p className="wr-donation-body1 mb-3">
              A Gift Aid declaration allows us to claim tax back on your gift
            </p>
            <p className="wr-donation-subtitle1 mb-3">
              With Gift Aid, your donation of{' '}
              <span className="text-primary fw-bold">
                {formatMoney(totalAmount, '£')}
              </span>{' '}
              will be
              <span className="text-primary fw-bold">
                {' '}
                {formatMoney(amountWithGiftAid, '£')}
              </span>{' '}
              with no extra to you
            </p>
            <div>
              <Form.Check
                type="switch"
                id="wr-donation-gift-aid-switch"
                className="m-0"
                inline
                label={
                  <label
                    htmlFor="wr-donation-gift-aid-switch"
                    className="wr-donation-body1"
                  >
                    I am a UK taxpayer, Reclaim gift aid on my donation
                  </label>
                }
                checked={giftAid}
                onChange={e => {
                  setGiftAid(e.target.checked);
                }}
              />
            </div>
          </Col>
          <Col
            md={4}
            className={classNames('align-self-center order-0', {
              'order-md-1': logoPosition === 'right'
            })}
          >
            <Image src={img} fluid />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export { GiftAidCard };

GiftAidCard.propTypes = {
  logoPosition: PropTypes.oneOf(['below_header', 'right'])
};
