import React, { useContext, useMemo, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { RHFSelect } from 'components/common/form';
import PropTypes from 'prop-types';
import { locationTypesMap } from 'components/donations/donation-settings/Locations';
import { gql, useQuery } from '@apollo/client';
import AuthContext from 'context/authContext';
import { getCitiesOptions } from 'data/options/countris-and-cities';
import { GET_LOCATION_CONDITIONS } from '../queries';
import AddOptionModal from 'components/common/modals/AddOptionModal';

const GET_LOCATIONS = gql`
  query GetLocations($organizationId: uuid = "", $type: String = "") {
    location(
      where: { organisation_id: { _eq: $organizationId }, type: { _eq: $type } }
    ) {
      id
      created_at
      location_name
      updated_at
      type
      organisation_id
    }
  }
`;

export const getCountriesOptions = locations =>
  locations.map(location => ({
    label: location.location_name,
    value: location.location_name
  }));

export const LocationCard = ({ sectionRef, sideNavLink, country }) => {
  const { organization } = useContext(AuthContext);

  const { data: locationConditions } = useQuery(GET_LOCATION_CONDITIONS, {
    fetchPolicy: 'network-only'
  });

  const conditionsOptions = locationConditions?.location_condition?.map(
    ({ id, name }) => ({ value: id, label: name })
  );

  const { data } = useQuery(GET_LOCATIONS, {
    fetchPolicy: 'network-only',
    variables: {
      organizationId: organization.id,
      type: locationTypesMap.sponsorship
    }
  });

  const countriesOptions = useMemo(() => {
    return data?.location ? getCountriesOptions(data.location) : [];
  }, [data]);

  const citiesOptions = useMemo(() => {
    if (country) {
      return getCitiesOptions(country);
    }
    return [];
  }, [country]);

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <AddOptionModal
          show={showModal}
          handleClose={closeModal}
          title="Location Condition"
          entityName="location_condition"
        />
      )}

      <Card
        className="border shadow-none mb-3"
        ref={sectionRef}
        id={sideNavLink}
      >
        <Card.Header className="pb-0">
          <div className="dFlexRowStartSpace pt-3">
            <Card.Title as="h5">Location</Card.Title>
          </div>
        </Card.Header>
        <Card.Body>
          <Row className="gx-3 gy-3">
            <Col xs={12} sm={6} md={4} className="pt-3">
              <RHFSelect
                name="location.country"
                label="Country"
                required
                options={countriesOptions}
              />
            </Col>
            <Col xs={12} sm={6} md={4} className="pt-3">
              <RHFSelect
                name="location.city"
                label="City"
                required
                options={citiesOptions}
                isDisabled={citiesOptions.length === 0}
              />
            </Col>
            <Col xs={12} sm={6} md={4} className="pt-3">
              <RHFSelect
                name="location.condition"
                label="Location Condition"
                options={conditionsOptions}
                hasAddOption
                onClickAdd={openModal}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

LocationCard.propTypes = {
  sectionRef: PropTypes.object,
  sideNavLink: PropTypes.string,
  country: PropTypes.string
};
