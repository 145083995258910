import { Button, Image } from 'react-bootstrap';

import { useDonationContextProvider } from 'context/DonationContextProvider';

import { CartIcon } from 'assets/icons/cart';
import logo from 'assets/img/we-raise-images/help-yateem.png';

import { CartDrawer } from './CartDrawer';
import { GiftAidDrawer } from './GiftAidDrawer';

const HEADER_HEIGHT = 94;

function DonationHeader() {
  const { basket, setIsCartDrawerOpen } = useDonationContextProvider();

  return (
    <>
      {/* <div className="subheader text-center py-2">
        <a href="tel:+201021312451254" className="text-black">
          +201021312451254
        </a>
      </div> */}
      <header className=" h-94 d-flex justify-content-between align-items-center wr-donation-shadow">
        <div className="container d-flex justify-content-between align-items-center w-100">
          <Image src={logo} alt="Help Yateem" className="img-fluid" />
          <div className="d-flex flex-column flex-md-row gap-2 gap-md-4 align-items-center">
            <Button
              className="d-flex justify-content-center align-items-center wr-donation-btn wr-donation-cart-btn bg-wr-donation-primary w-auto"
              onClick={() => setIsCartDrawerOpen(true)}
            >
              <CartIcon />
              <span className="">{basket.length}</span>
              <span className="d-none d-md-inline">Donation</span>
            </Button>
          </div>
        </div>
      </header>
      <CartDrawer />
      <GiftAidDrawer />
    </>
  );
}

export { DonationHeader, HEADER_HEIGHT };
