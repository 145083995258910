import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';

import paths from 'routes/paths';
import { calculateAge } from 'helpers/utils';

import { gql, useQuery } from '@apollo/client';

import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import LoadingContainer from 'components/common/LoadingContainer';

const GET_BENEFICIARY = gql`
  query GetBeneficiary($id: uuid!) {
    orphans_by_pk(id: $id) {
      country
      first_name
      gender
      last_name
      middle_name
      image
      DOB
      id
    }
  }
`;

const CreateSponsorNav = props => {
  const { onChangeBeneficiary } = props;

  const [stickyStatus, setStickyStatus] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const beneficiaryId =
    searchParams.get('beneficiaryId') || searchParams.get('id');

  const { data, loading } = useQuery(GET_BENEFICIARY, {
    variables: { id: beneficiaryId }
  });
  const beneficiary = data?.orphans_by_pk;

  const { first_name, last_name, image, country, gender, DOB } =
    beneficiary || {};

  const fullName = first_name + ' ' + last_name;
  const age = calculateAge(DOB);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY < 10) {
        setStickyStatus(false);
      } else {
        setStickyStatus(true);
      }
    });
  }, []);

  if (loading) return <LoadingContainer />;

  return (
    beneficiary && (
      <div
        className="font-sans-serif sticky-top"
        style={{ top: stickyStatus ? '5rem' : '0' }}
      >
        <Card className="mb-3">
          <Card.Body>
            <Flex
              justifyContent="between"
              alignItems="center"
              className={'mb-3'}
            >
              <h6 className="mb-0">Selected Beneficiary</h6>
            </Flex>
            <div className="text-center">
              <Avatar
                src={image}
                name={fullName}
                rounded="circle"
                size="4xl"
                className="border border-5 border-white rounded-circle shadow-sm mb-2"
              />

              <p className="mb-1 fs--1 text-primary fw-medium">
                {fullName}
                <IconButton
                  onClick={() =>
                    // navigate(parseRouteParams(paths.editDonor, { id: '' }))
                    navigate(
                      paths.beneficiaryDetails.replace(':id', beneficiaryId)
                    )
                  }
                  variant="default"
                  className="p-0 shadow-none fs--1"
                  iconClassName={'ms-2'}
                  icon={'external-link-alt'}
                />
              </p>
              <p className="m-0">
                {country} | {gender} | {age}
              </p>
              <hr className="mb-0 mt-3" />
              <Button
                size="sm"
                variant="secondary"
                onClick={onChangeBeneficiary}
                className="fs--1 mt-2"
              >
                Change Beneficiary
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
    )
  );
};

CreateSponsorNav.propTypes = {
  onChangeBeneficiary: PropTypes.func
};

export default CreateSponsorNav;
