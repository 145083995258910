import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useAuthContext } from 'context/authContext';

import { GET_DONORS, GET_DONATIONS, GET_DONATION_ITEMS } from './queries';
import { useQuery, useLazyQuery } from '@apollo/client';

import { Button, Col, Row } from 'react-bootstrap';
import { FormProvider, RHFSelect } from 'components/common/form';

function SponsorshipItemsForm(props) {
  const { form, onSubmit } = props;

  const { organization } = useAuthContext();

  const selectedDonorId = form.watch('donorId');
  const selectedDonationId = form.watch('donationId');

  const { data: donorsData, loading: isDonorsDataLoading } = useQuery(
    GET_DONORS,
    {
      variables: {
        where: { organisation_id: { _eq: organization.id } }
      }
    }
  );
  const [
    getDonorDonations,
    { data: donationsData, loading: isDonationsDataLoading }
  ] = useLazyQuery(GET_DONATIONS);
  useEffect(() => {
    if (selectedDonorId) {
      void getDonorDonations({
        variables: {
          where: {
            organisation_id: { _eq: organization.id },
            donar_id: { _eq: selectedDonorId }
          }
        }
      });
    }
  }, [selectedDonorId, getDonorDonations, organization]);

  const [
    getDonationItems,
    { data: donationItemsData, loading: isDonationItemsDataLoading }
  ] = useLazyQuery(GET_DONATION_ITEMS);
  useEffect(() => {
    if (selectedDonationId) {
      void getDonationItems({
        variables: {
          where: {
            order_id: { _eq: selectedDonationId }
          }
        }
      });
    }
  }, [selectedDonationId, getDonationItems]);

  const donorList = (donorsData?.donars ?? []).map(donor => {
    const {
      simple_id: simpleId,
      first_name: firstName,
      last_name: lastName,
      email,
      phone,
      address
    } = donor;

    const emailAddress = email[0]?.email;
    const phoneNumber = phone[0]?.phoneNumber;
    const city = address[0]?.city;

    return {
      inputValue: `${firstName} ${lastName}`,
      label: (
        <div className="d-flex flex-column fs--1">
          <strong>#{simpleId}</strong>
          <strong>
            {firstName} {lastName}
          </strong>
          <span>{emailAddress}</span>
          <span>
            {city} {city && ' | '} {phoneNumber}
          </span>
        </div>
      ),
      value: donor.id,
      search: `${simpleId} ${firstName} ${lastName} ${emailAddress} ${phoneNumber} ${city}`
    };
  });

  const donationsList = (donationsData?.orders ?? []).map(donation => {
    const { id, donation_amount, payment_status, payment_method } = donation;

    return {
      inputValue: `${id}`,
      label: (
        <div className="d-flex flex-column fs--1">
          <strong>#{id}</strong>
          <span>
            <strong>Amount:</strong> ${donation_amount}
          </span>
          <span>
            <strong>Payment Status:</strong> {payment_status}
          </span>
          {payment_method && (
            <span>
              <strong>Payment Method:</strong> {payment_method.payment_name}
            </span>
          )}
        </div>
      ),
      value: id,
      search: `${id} ${donation_amount} ${payment_status} ${payment_method?.payment_name} `
    };
  });

  const donationItemsList = (donationItemsData?.order_details ?? []).map(
    donationItem => {
      const { id, billing_condition, amount, donation_type } = donationItem;

      return {
        inputValue: `${id}`,
        label: (
          <div className="d-flex flex-column fs--1">
            <strong>#{id}</strong>
            <span>
              <strong>Amount:</strong> ${amount}
            </span>
            <span>
              <strong>Billing Condition:</strong> {billing_condition}
            </span>
            <span>
              <strong>Donation Type:</strong>{' '}
              {donation_type?.donation_type_name}
            </span>
          </div>
        ),
        value: id,
        search: `${id} ${amount} ${billing_condition} ${donation_type?.donation_type_name}`
      };
    }
  );

  const filterOption = (option, searchText) => {
    if (searchText.trim() !== '') {
      const search = option.data.search;
      return search.toLowerCase().includes(searchText.toLowerCase());
    }
    return true;
  };

  const submitHandler = data => {
    const { donorId, donationId, orderItemId } = data;
    onSubmit({
      donor: donorsData.donars.find(donor => donor.id === donorId),
      donation: donationsData.orders.find(
        donation => donation.id === donationId
      ),
      orderItem: donationItemsData.order_details.find(
        donationItem => donationItem.id === orderItemId
      )
    });
  };

  return (
    <FormProvider methods={form}>
      <div className="border rounded-2 p-3">
        <Row>
          <Col as={Col} md={6} className="mb-3">
            <RHFSelect
              label="Select Donor"
              name="donorId"
              isLoading={isDonorsDataLoading}
              options={donorList}
              formatOptionLabel={(option, { context }) =>
                context === 'value' ? option.inputValue : option.label
              }
              filterOption={filterOption}
              required
            />
          </Col>
          <Col as={Col} md={6} className="mb-3">
            <RHFSelect
              label="Select Donation"
              name="donationId"
              isLoading={isDonationsDataLoading}
              options={donationsList}
              formatOptionLabel={(option, { context }) =>
                context === 'value' ? option.inputValue : option.label
              }
              filterOption={filterOption}
              required
              isDisabled={!selectedDonorId}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col as={Col} md={12}>
            <RHFSelect
              label="Select Donation Item"
              name="orderItemId"
              isLoading={isDonationItemsDataLoading}
              options={donationItemsList}
              formatOptionLabel={(option, { context }) =>
                context === 'value' ? option.inputValue : option.label
              }
              filterOption={filterOption}
              required
              isDisabled={!selectedDonationId}
            />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button onClick={form.handleSubmit(submitHandler)}>Add</Button>
          </Col>
        </Row>
      </div>
    </FormProvider>
  );
}

SponsorshipItemsForm.propTypes = {
  form: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default SponsorshipItemsForm;
