import classNames from 'classnames';
import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

export const CustomDonationCheck = ({ checked, onClick, children }) => {
  const checkedClassNames =
    'wr-donation-text-primary bg-wr-donation-yellow-500 border-wr-donation-yellow-500';
  const uncheckedClassNames =
    'bg-wr-donation-grey-0 wr-donation-text-grey-900 border border-2';
  return (
    <Button
      className={classNames(
        'outline-none rounded-3  fs-sm-1 w-100 p-0 d-flex justify-content-center align-items-center py-3',
        {
          [checkedClassNames]: checked,
          [uncheckedClassNames]: !checked
        }
      )}
      // style={{ width: '245px', height: '64px' }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

CustomDonationCheck.propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node
};
