import { Card, Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { RHFControl, RHFSelect } from 'components/common/form';
import { useContext, useMemo } from 'react';
import { GET_SPONSORSHIP_COST } from './beneficiary-options-queries';
import AuthContext from 'context/authContext';
import { useQuery } from '@apollo/client';

export const SponsorshipCostCard = ({
  setValue,
  selectedCost,
  otherCost,
  sectionRef,
  sideNavLink
}) => {
  const { organization } = useContext(AuthContext);

  const { data: costData } = useQuery(GET_SPONSORSHIP_COST, {
    fetchPolicy: 'network-only',
    variables: {
      organizationId: organization.id
    }
  });

  const costOptions = useMemo(
    () =>
      costData?.sponsorship_cost?.map(cost => {
        return {
          value: cost.amount,
          label: `$${cost.amount.toFixed(2)}`
        };
      }),
    [costData]
  );

  return (
    <Card className="border shadow-none mb-3" ref={sectionRef} id={sideNavLink}>
      <Card.Header className="pb-0">
        <div className="dFlexRowStartSpace pt-3">
          <Card.Title as="h5">Sponsorship Cost</Card.Title>
        </div>
      </Card.Header>
      <Card.Body>
        <Container className="p-0 d-flex flex-column g-3">
          <Row className="gx-3">
            <Col xs={12} sm={6}>
              <RHFSelect
                name=""
                onChange={option => {
                  setValue('sponsorship.selectedCost', option.value);
                  setValue('sponsorship.otherCost', '');
                }}
                value={
                  costOptions?.find(option => option.value === selectedCost) ??
                  null
                }
                label="Select Cost"
                options={costOptions}
                required={!otherCost}
              />
            </Col>
            <Col xs={12} sm={6}>
              <RHFControl
                type="number"
                name=""
                onChange={e => {
                  setValue('sponsorship.otherCost', e.target.value);
                  setValue('sponsorship.selectedCost', '');
                }}
                value={otherCost}
                label="Other Cost"
                options={costOptions}
                required={!selectedCost}
              />
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

SponsorshipCostCard.propTypes = {
  setValue: PropTypes.func.isRequired,
  selectedCost: PropTypes.string,
  otherCost: PropTypes.string,
  values: PropTypes.object.isRequired,
  sectionRef: PropTypes.object,
  sideNavLink: PropTypes.string
};
