import { gql, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DonarFormContainer from './donor-form/DonorFormContainer';
import { GET_DONORS } from '.';
import { useContext } from 'react';
import AuthContext from 'context/authContext';

/* -------------------------------------------------------------------------- */
const CREATE_DONOR = gql`
  mutation CreateDonor(
    $address: jsonb = ""
    $primary_address: String
    $consented_date: timestamp = ""
    $consented_via: String = ""
    $contact_preferences: jsonb = ""
    $declaration_source: String = ""
    $email: jsonb = ""
    $primary_email: String
    $first_name: String = ""
    $gift_aid_file: String = ""
    $last_name: String = ""
    $comment: String = ""
    $lead_source: String = ""
    $organisation_id: uuid = null
    $phone: jsonb = ""
    $primary_phone: String
    $remark: String = ""
    $staff_id: uuid = null
    $title: String = ""
    $gift_aid_elgibility: Boolean = false
    $tax_vat_number: String = ""
    $username: String = ""
    $website: String = ""
    $job_title: String = ""
    $charity_registration_number: String = ""
    $partner_name: String = ""
    $type: String = ""
    $donor_category_id: uuid!
  ) {
    insert_donars(
      objects: {
        address: $address
        primary_address: $primary_address
        consented_date: $consented_date
        consented_via: $consented_via
        contact_preferences: $contact_preferences
        declaration_source: $declaration_source
        email: $email
        primary_email: $primary_email
        first_name: $first_name
        gift_aid_elgibility: $gift_aid_elgibility
        gift_aid_file: $gift_aid_file
        last_name: $last_name
        comment: $comment
        lead_source: $lead_source
        organisation_id: $organisation_id
        phone: $phone
        primary_phone: $primary_phone
        remark: ""
        user_id: $staff_id
        title: $title
        tax_vat_number: $tax_vat_number
        username: $username
        website: $website
        job_title: $job_title
        charity_registration_number: $charity_registration_number
        partner_name: $partner_name
        type: $type
        donor_category_id: $donor_category_id
      }
    ) {
      returning {
        address
        consented_date
        consented_via
        contact_preferences
        created_at
        declaration_source
        email
        first_name
        gift_aid_elgibility
        gift_aid_file
        id
        last_name
        comment
        lead_source
        organisation_id
        phone
        remark
        title
        user_id
        type
        donor_category_id
      }
    }
  }
`;
/* -------------------------------------------------------------------------- */
const CreateDonor = () => {
  const navigate = useNavigate();
  const { organization } = useContext(AuthContext);
  const [createDonar, { loading }] = useMutation(CREATE_DONOR, {
    refetchQueries: ['GetDonors', 'GetTotalDonor', 'GetTotalDonorByDate'],
    onCompleted: data => {
      toast.success('Donor created successfully');
      navigate(`/donors/${data?.insert_donars?.returning[0]?.id}/profile`);
    },
    onError: error => {
      toast.error('Something went wrong! Please try again');
      console.log(error);
    },
    update: (cache, { data }) => {
      const newDonar = data.insert_donars.returning[0];
      const existingDonars = cache.readQuery({
        query: GET_DONORS,
        variables: { organizationId: organization.id }
      });
      if (!existingDonars) return;
      cache.writeQuery({
        query: GET_DONORS,
        variables: { organizationId: organization.id },
        data: {
          donars: [newDonar, ...existingDonars.donars]
        }
      });
    }
  });

  const handleCreate = data => {
    createDonar({
      variables: data
    });
  };

  return <DonarFormContainer loading={loading} onSubmit={handleCreate} />;
};

export default CreateDonor;
