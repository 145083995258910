import * as Yup from 'yup';

export const SponsorshipItemSchema = Yup.object().shape({
  donorId: Yup.string().required('Required Field'),
  donationId: Yup.string().required('Required Field'),
  orderItemId: Yup.string().required('Required Field')
});

export const sponsorshipScheme = Yup.object().shape({
  beginOn: Yup.string().required('Required Field'),
  items: Yup.array()
    .of(
      Yup.object().shape({
        donor: Yup.object,
        donation: Yup.object,
        orderItem: Yup.object
      })
    )
    .min(1, 'At least one order item is required')
});
