import * as Yup from 'yup';

export const beneficiaryScheme = Yup.object().shape({
  firstName: Yup.string().required('Required Field'),
  middleName: Yup.string(),
  lastName: Yup.string().required('Required Field'),
  gender: Yup.string().required('Required Field'),
  dateOfBirth: Yup.string().required('Required Field'),
  language: Yup.string(),

  type: Yup.string().required('Required Field'),

  location: Yup.object().shape({
    city: Yup.string().required('Required Field'),
    condition: Yup.string(),
    country: Yup.string().required('Required Field')
  }),

  healthCondition: Yup.string().required('Required Field'),
  healthNotes: Yup.string(),

  guardian: Yup.object().shape({
    firstName: Yup.string(),
    middleName: Yup.string(),
    lastName: Yup.string(),

    email: Yup.string().email('Invalid Email'),
    phone: Yup.string(),
    address: Yup.string(),

    fatherStatus: Yup.string().nullable(),
    motherStatus: Yup.string().nullable(),
    relationShip: Yup.string().nullable()
  }),

  inspiration: Yup.string(),

  sponsorship: Yup.object().shape({
    duration: Yup.string().required('Required Field'),
    selectedCost: Yup.string(),
    otherCost: Yup.string(),
    status: Yup.string().required('Required Field'),
    priorityId: Yup.string().required('Required Field')
  })
});
