import PropTypes from 'prop-types';

import { useDonationContextProvider } from 'context/DonationContextProvider';

import { CoverTransactionFee } from '../CoverTransactionFee';
import { Button } from 'react-bootstrap';
import { formatMoney } from 'helpers/utils';

function DonationSummary({ onCheckout, onClickBack }) {
  const { basket, totalAmount } = useDonationContextProvider();

  return (
    <aside>
      <h2 className="text-uppercase wr-donation-header2 mb-3">
        donation summary
      </h2>
      <div className="d-flex flex-column gap-3 mb-3">
        {basket.map(donation => (
          <DonationItem key={donation.id} donation={donation} />
        ))}
      </div>
      <p className="mb-3 wr-donation-body2 wr-donation-text-brand-secondary">
        Help us farther
      </p>
      <CoverTransactionFee />
      <div className="wr-donation-summary-card d-flex flex-column gap-3">
        <header className="wr-donation-text-brand-secondary fw-bold d-flex justify-content-between align-items-center">
          <span>Total</span>
          <span>{totalAmount.toFixed(2)}</span>
        </header>
        <Button
          className="border-0 bg-wr-donation-success-500 py-3"
          onClick={onCheckout}
        >
          Pay by Debit / Credit card &gt;
        </Button>
        <Button
          className="border-0 bg-wr-donation-grey-150 wr-donation-text-grey-800 py-3"
          onClick={onClickBack}
        >
          &lt; Back
        </Button>
      </div>
    </aside>
  );
}

export function DonationItem({ donation }) {
  const { paymentType, count, donationAmount, donationType } = donation;

  return (
    <div className="wr-donation-summary-card d-flex flex-column gap-3">
      <header className="d-flex justify-content-between align-items-center wr-donation-text-brand-secondary">
        <h3 className="text-capitalize mb-0 fw-semi-bold">
          {donation.packageId ? donation.packageName : 'Quick Donation'}
        </h3>
        <span className="fs-2 fw-semi-bold">
          {formatMoney(count * donationAmount.value, '£')}
        </span>
      </header>
      <ul>
        <li>{paymentType}</li>
        <li>{donationType.name}</li>
      </ul>
    </div>
  );
}

DonationSummary.propTypes = {
  onCheckout: PropTypes.func.isRequired,
  onClickBack: PropTypes.func.isRequired
};
DonationItem.propTypes = {
  donation: PropTypes.object.isRequired
};
export { DonationSummary };
