import { gql, useMutation } from '@apollo/client';
import AuthContext from 'context/authContext';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import paths from 'routes/paths';
import { GET_BENEFACTORS } from '.';
import BeneficiaryForm from './beneficiary-form/BeneficiaryForm';
import useBranchPermission from '../../hooks/useBranchPermission';

const CREATE_GUARDIAN = gql`
  mutation CreateGuardian(
    $first_name: String = ""
    $middle_name: String = ""
    $last_name: String = ""
    $email: String = ""
    $phone: String = ""
    $address: String = ""
    $guardian_father_status_id: uuid
    $guardian_mother_status_id: uuid
    $guardian_relationShip_id: uuid
  ) {
    insert_guardian_one(
      object: {
        first_name: $first_name
        middle_name: $middle_name
        last_name: $last_name
        email: $email
        phone: $phone
        address: $address
        guardian_father_status_id: $guardian_father_status_id
        guardian_mother_status_id: $guardian_mother_status_id
        guardian_relationShip_id: $guardian_relationShip_id
      }
    ) {
      id
    }
  }
`;

const CREATE_BENEFICIARY = gql`
  mutation CreateBeneficiary(
    $DOB: timestamp = ""
    $first_name: String = ""
    $last_name: String = ""
    $middle_name: String = ""
    $gender: String = ""
    $language: String = ""
    $address: String = ""
    $country: String = ""
    $city: String = ""
    $location_condition: String = ""
    $sponsorship_condition_id: uuid = ""
    $status: String = ""
    $health_notes: String = ""
    $inspiration: String = ""
    $cost: numeric
    $duration: jsonb = ""
    $sponsorship_type_id: uuid = ""
    $priorityId: uuid = ""
    $image: String = ""
    $organisation_id: uuid = ""
    $branch_id: uuid = ""
    $guardian_id: uuid = ""
  ) {
    insert_orphans_one(
      object: {
        DOB: $DOB
        first_name: $first_name
        middle_name: $middle_name
        last_name: $last_name
        gender: $gender
        language: $language
        address: $address
        country: $country
        city: $city
        location_condition: $location_condition
        sponsorship_condition_id: $sponsorship_condition_id
        status: $status
        health_notes: $health_notes
        inspiration: $inspiration
        duration: $duration
        sponsorship_type_id: $sponsorship_type_id
        sponsorship_priority_id: $priorityId
        image: $image
        organisation_id: $organisation_id
        branch_id: $branch_id
        guardian_id: $guardian_id
        cost: $cost
      }
    ) {
      first_name
      middle_name
      gender
      country
      DOB
      gender
      sponsorship_condition {
        condition
      }
      sponsorship_priority {
        priority_type
      }
      cost
      status
      duration
      sponsorship_type {
        type
      }
      id
      last_name
      image
      location_condition_id
      address
    }
  }
`;

function CreateBeneficiary() {
  const navigate = useNavigate();

  const { organization, selectedBranchId } = useContext(AuthContext);
  const { where } = useBranchPermission();

  const [createGuardian, { loading: isCreateGuardianLoading }] =
    useMutation(CREATE_GUARDIAN);
  const [createBeneficiary, { loading }] = useMutation(CREATE_BENEFICIARY, {
    refetchQueries: [
      {
        query: GET_BENEFACTORS,
        variables: { where: { ...where, is_hidden: { _eq: false } } }
      }
    ], // Refetch the beneficiaries query
    onCompleted: () => {
      toast.success('Beneficiary created successfully');
      navigate(paths.beneficiaries);
    },
    onError: error => {
      toast.error(error.message);
      console.error(error);
    }
  });

  const onSubmit = async data => {
    try {
      const res = await createGuardian(data.guardian);
      const guardian_id = res.data.insert_guardian_one.id;
      void createBeneficiary({
        variables: {
          ...data,
          guardian_id,
          organisation_id: organization.id,
          branch_id: selectedBranchId
        }
      });
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <BeneficiaryForm
      onSubmit={onSubmit}
      loading={loading || isCreateGuardianLoading}
    />
  );
}

export default CreateBeneficiary;
