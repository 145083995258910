import { useContext, useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import useScrollSpy from 'react-use-scrollspy';

import AuthContext from 'context/authContext';

import { gql, useMutation, useQuery } from '@apollo/client';

import { GET_DONORS } from 'components/add-donation/donation-form-components/ADSelectDonor';
import { GIFT_AID_STATUS_UNCLAIMED } from 'components/gift-aid/unclaimed-transactions';

import { getRelativePath } from 'helpers/utils';
import paths from 'routes/paths';

import { toast } from 'react-toastify';

import DonationConfig from 'components/add-donation/DonationConfig';
import Payment from 'components/add-donation/Payment';
import { Card, Col, Nav, Row } from 'react-bootstrap';

export const INSERT_ORDER = gql`
  mutation InsertOrder(
    # $email: String!
    # $organization_id: uuid!
    $branch_id: String
    $order_details: String!
    $payment_method_id: String!
    $subtotal: String!
    $transaction_fees: String!
    $donation_amount: String!
    $gift_aid_eligible: Boolean!
    $gift_aids: String
    # $first_name: String!
    # $last_name: String!
    $donor_object: String = null
    $donor_id: String = null
  ) {
    insertOrder(
      # email: $email
      # organization_id: $organization_id
      branch_id: $branch_id
      order_details: $order_details
      payment_method_id: $payment_method_id
      subtotal: $subtotal
      transaction_fees: $transaction_fees
      donation_amount: $donation_amount
      gift_aid_eligible: $gift_aid_eligible
      gift_aids: $gift_aids
      # first_name: $first_name
      # last_name: $last_name
      donor_object: $donor_object
      donor_id: $donor_id
    ) {
      message
      order_id
    }
  }
`;

const sideNavLinks = [
  { id: 1, href: '#details', title: 'Donation Details' },
  { id: 2, href: '#appeals', title: 'Selected Appeals' },
  { id: 3, href: '#settings', title: 'Advanced Settings' }
];

function AddDonation() {
  const [data, setData] = useState({});
  const [stickyStatus, setStickyStatus] = useState(false);

  const navigate = useNavigate();

  const sectionRefs = [useRef(null), useRef(null), useRef(null)];
  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -150
  });
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY < 100) {
        setStickyStatus(false);
      } else {
        setStickyStatus(true);
      }
    });
  }, []);

  const { organization, selectedBranchId } = useContext(AuthContext);
  const [insertOrder, { loading }] = useMutation(INSERT_ORDER, {
    onCompleted: response => {
      if (response.insertOrder.order_id) {
        navigate(paths.allDonations);
      } else {
        toast.error('Something went wrong');
      }
    },
    onError: error => {
      toast.error('Something went wrong');
      console.log(error);
    }
  });
  // const [createDonation, { loading }] = [() => {}, { loading: false }];
  // = useMutation(CREATE_DONATION, {
  //   onCompleted: dataDonation => {
  //     setData({ ...data, ...dataDonation });
  //     navigate(getRelativePath(paths.addDonationPayment, paths.addDonation));
  //   },
  //   onError: error => {
  //     toast.error('Something went wrong');
  //     console.log(error);
  //   }
  // });
  const { data: donorsData } = useQuery(GET_DONORS, {
    variables: {
      where: {
        organisation_id: { _eq: organization.id }
      }
    }
  });

  const onSubmitDonationConfig = data => {
    const {
      donorId,
      donationItems,
      subtotal,
      transactionFees,
      total,
      settings
    } = data;

    const giftAidData = {
      data: {
        amount: total * 0.25,
        donar_id: donorId,
        status: GIFT_AID_STATUS_UNCLAIMED
      }
    };
    setData({
      donorId,
      donationItems,
      subtotal,
      transactionFees,
      total,
      settings,
      organization: organization.id,
      donorsData,
      giftAidData
    });
    console.log({ data });
    navigate(paths.addDonationPayment);
  };

  const onPay = async paymentMethodId => {
    try {
      const {
        donorId,
        donationItems,
        subtotal,
        transactionFees,
        total,
        settings,
        giftAidData
      } = data;

      const orderDetails = JSON.stringify(
        donationItems.map(({ amount, values, type, packageId }) => ({
          amount,
          billing_condition: values.billingCondition,
          donation_type_id: type,
          package_id: packageId
        }))
      );

      await insertOrder({
        variables: {
          // email: donorEmail,
          // organization_id: organization.id,
          donor_id: donorId,
          branch_id: selectedBranchId,
          order_details: orderDetails,
          payment_method_id: paymentMethodId,
          subtotal: subtotal.toString(),
          transaction_fees: (settings.coverTransactionFee
            ? transactionFees
            : 0
          ).toString(),
          donation_amount: total.toString(),
          gift_aid_eligible: settings.giftAid,
          gift_aids: settings.giftAid ? JSON.stringify(giftAidData) : null
          // gift_aids: null
          // first_name: data.first_name,
          // last_name: data.last_name
        }
      });
    } catch (error) {
      toast.error('Something went wrong');
      console.log(error);
    }
  };

  return (
    <Row className="pt-3 gx-3">
      <Col md={9}>
        <Routes>
          <Route
            path={getRelativePath(paths.addDonationConfig, paths.addDonation)}
            element={
              <DonationConfig
                onSubmit={onSubmitDonationConfig}
                loading={loading}
              />
            }
          />
          <Route
            path={getRelativePath(paths.addDonationPayment, paths.addDonation)}
            element={<Payment onPay={onPay} loading={loading} data={data} />}
          />
          <Route
            path={'/*'}
            element={
              <Navigate
                to={getRelativePath(paths.addDonationConfig, paths.addDonation)}
              />
            }
          />
        </Routes>
      </Col>
      <Col md={3} className="order-0 order-md-1 d-none d-md-block">
        <Card
          className={'mb-3 sticky-top border shadow-none'}
          style={{ top: stickyStatus ? '5rem' : '0' }}
        >
          <Card.Body>
            <div>
              <Nav className="flex-column" variant="pills">
                {sideNavLinks?.map(item => (
                  <Nav.Link
                    key={item.id}
                    href={item.href}
                    className={`${
                      activeSection === item.id
                        ? 'current custom-nav-link'
                        : 'custom-nav-link'
                    }`}
                    active={activeSection >= item.id}
                  >
                    {item.title}
                  </Nav.Link>
                ))}
              </Nav>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export { AddDonation };
