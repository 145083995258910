const BeneficiaryStatus = Object.freeze({
  pending: 'pending',
  partially: 'partially',
  onHold: 'on_hold',
  transferred: 'transferred',
  completed: 'completed',
  rejected: 'rejected',
  inReview: 'in_review',
  inactive: 'in_active',
  archived: 'archived',
  pendingRenewal: 'pending_renewal'
});

const StatusLookup = new Map([
  [BeneficiaryStatus.pending, { label: 'Pending', colorClassName: 'warning' }],
  [BeneficiaryStatus.partially, { label: 'Partially', colorClassName: 'info' }],
  [BeneficiaryStatus.onHold, { label: 'On Hold', colorClassName: 'secondary' }],
  [
    BeneficiaryStatus.transferred,
    { label: 'Transferred', colorClassName: 'primary' }
  ],
  [
    BeneficiaryStatus.completed,
    { label: 'Completed', colorClassName: 'success' }
  ],
  [BeneficiaryStatus.rejected, { label: 'rejected', colorClassName: 'danger' }],
  [BeneficiaryStatus.inReview, { label: 'In Review', colorClassName: 'info' }],
  [
    BeneficiaryStatus.inactive,
    { label: 'Inactive', colorClassName: 'secondary' }
  ],
  [
    BeneficiaryStatus.archived,
    { label: 'Archived', colorClassName: 'secondary' }
  ],
  [
    BeneficiaryStatus.pendingRenewal,
    { label: 'Pending Renewal', colorClassName: 'warning' }
  ]
]);

export { StatusLookup };
