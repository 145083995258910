import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import LoadingButton from 'components/common/LoadingButton';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Card, Row } from 'react-bootstrap';

const PaymentForm = ({ onPay }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement
      });
      if (error) {
        throw new Error(error.message);
      }

      await onPay(paymentMethod.id);
    } catch (error) {
      setError(error.message);
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className="max">
      <Card className="shadow-none border mt-3">
        <Card.Body className="pb-1">
          <Row className="g-3">
            <CardElement />
          </Row>
        </Card.Body>
        {error && (
          <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>
        )}
        <Card.Footer className="d-flex justify-content-end">
          <LoadingButton
            loading={loading}
            // onClick={onSubmitAddCard}
            type="submit"
            className="w-100 py-3 mt-3"
          >
            Pay
          </LoadingButton>
        </Card.Footer>
      </Card>
      {/* <LoadingButton
        type="submit"
        disabled={!stripe || loading}
        loading={loading}
      >
        {loading ? 'Processing...' : 'Pay'}
      </LoadingButton> */}
    </form>
  );
};

PaymentForm.propTypes = {
  onPay: PropTypes.func.isRequired
};

export default PaymentForm;
