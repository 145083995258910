import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useAuthContext } from 'context/authContext';

import { useQuery } from '@apollo/client';
import { GET_SPONSORSHIP_PRIORITY } from './beneficiary-options-queries';

import { RHFSelect } from 'components/common/form';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import paths from 'routes/paths';

import { StatusLookup } from '../data';

export const SponsorshipStatus = ({ sectionRef, sideNavLink }) => {
  const { organization } = useAuthContext();

  const { data: priorityData } = useQuery(GET_SPONSORSHIP_PRIORITY, {
    fetchPolicy: 'network-only',
    variables: {
      organizationId: organization.id
    }
  });
  const sponsorshipPriorityOptions = useMemo(() => {
    if (priorityData) {
      return priorityData.sponsorship_priority.map(priority => ({
        label: priority.priority_type,
        value: priority.id
      }));
    }
    return [];
  }, [priorityData]);

  return (
    <Card className="border shadow-none mb-3" ref={sectionRef} id={sideNavLink}>
      <Card.Header className="pb-0">
        <div className="dFlexRowStartSpace pt-3">
          <Card.Title as="h5">Sponsorship Status</Card.Title>
          <Button
            variant="light"
            className="shadow-none bg-transparent border-0"
            style={{
              fontSize: 13
            }}
            as={Link}
            to={paths.sponsorshipValues}
          >
            <FontAwesomeIcon icon="pen" style={{ marginRight: '5px' }} />
            Edit
          </Button>
        </div>
      </Card.Header>
      <Card.Body>
        <Row className="gx-3">
          <Col xs={12} sm={6} className="pt-3">
            <RHFSelect
              name="sponsorship.status"
              label="Status"
              options={Array.from(StatusLookup).map(([key, value]) => ({
                label: value.label,
                value: key
              }))}
              required
            />
          </Col>
          <Col xs={12} sm={6} className="pt-3">
            <RHFSelect
              name="sponsorship.priorityId"
              label="Priority"
              options={sponsorshipPriorityOptions}
              required
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

SponsorshipStatus.propTypes = {
  sectionRef: PropTypes.object,
  sideNavLink: PropTypes.string
};
