import { useState, useRef, useEffect, useContext } from 'react';
import { Card, Col, Row, Nav } from 'react-bootstrap';
import useScrollSpy from 'react-use-scrollspy';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { parseISO } from 'date-fns';
import { FormProvider } from 'components/common/form';
import { ProfileInformation } from './ProfileInformation';
import { LocationCard } from './LocationCard';
import { GuardianDetails } from './GuardianDetails';
import { InspirationCard } from './InspirationCard';
import { SponsorshipStatus } from './SponsorshipStatus';
import AuthContext from 'context/authContext';
import LoadingButton from 'components/common/LoadingButton';
import { calculateAge } from 'helpers/utils';
import { beneficiaryScheme } from './beneficiaryScheme';
import useUpload from 'hooks/useUpload';
import { toast } from 'react-toastify';
import { SponsorshipTypeCard } from './SponsorshipTypeCard';
import { HealthConditionCard } from './HealthConditionCard';
import { SponsorshipDurationCard } from './SponsorshipDurationCard';
import { SponsorshipCostCard } from './SponsorshipCostCard';

export const BENEFICIARY_STATUS_SPONSORED = 'sponsored';
export const BENEFICIARY_STATUS_UNSPONSORED = 'unsponsored';

const BeneficiaryForm = ({ onSubmit, isEdit, data, costData, loading }) => {
  const { organization, selectedBranchId } = useContext(AuthContext);

  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -150
  });

  const [stickyStatus, setStickyStatus] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY < 100) {
        setStickyStatus(false);
      } else {
        setStickyStatus(true);
      }
    });
  }, []);

  const sideNavLinks = [
    { id: 1, href: '#info', title: 'Profile Information' },
    { id: 2, href: '#sponsorshipType', title: 'Sponsorship Type' },
    { id: 3, href: '#location', title: 'Location' },
    { id: 4, href: '#condition', title: 'Health Condition' },
    { id: 5, href: '#details', title: 'Guardian Details' },
    { id: 6, href: '#inspiration', title: 'Inspiration' },
    { id: 7, href: '#duration', title: 'Sponsorship Duration' },
    { id: 8, href: '#sponsorshipCost', title: 'Sponsorship Cost' },
    {
      id: 9,
      href: '#sponsorshipStatus',
      title: 'Sponsorship Status'
    }
  ];

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: '',
      middleName: '',
      lastName: '',
      gender: '',
      dateOfBirth: '',
      age: '',
      language: '',
      address: '',
      type: '',
      image: '',
      guardian: {
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        fatherStatus: null,
        motherStatus: null,
        relationship: null
      },
      healthCondition: '',
      healthNotes: '',
      location: {
        country: '',
        city: '',
        condition: ''
      },
      inspiration: '',
      sponsorship: {
        duration: '',
        selectedCost: '',
        otherCost: '',
        status: '',
        priorityId: ''
      }
    },
    resolver: yupResolver(beneficiaryScheme)
  });
  const { handleSubmit: RHFHandleSubmit, watch, setValue } = methods;

  const {
    location: { country },
    dateOfBirth,
    sponsorship: { selectedCost, otherCost }
  } = watch();

  useEffect(() => {
    const age = dateOfBirth ? calculateAge(dateOfBirth) : '';
    setValue('age', age);
  }, [dateOfBirth, setValue]);

  useEffect(() => {
    if (isEdit) {
      const {
        DOB,
        first_name,
        middle_name,
        last_name,
        gender,
        language,
        address,
        country,
        city,
        location_condition,
        sponsorship_condition_id,
        status,
        health_notes,
        inspiration,
        duration,
        sponsorship_type_id,
        sponsorship_priority_id,
        cost,
        image,
        guardian
      } = data;
      setValue('firstName', first_name);
      setValue('middleName', middle_name);
      setValue('lastName', last_name);
      const locationValues = {
        country,
        city,
        condition: location_condition
      };
      setValue('location', locationValues);
      const guardianValues = {
        fatherStatus: guardian.guardian_father_status?.id,
        motherStatus: guardian.guardian_mother_status?.id,
        relationship: guardian.guardian_relationShip?.id,
        firstName: guardian.first_name,
        middleName: guardian.middle_name,
        lastName: guardian.last_name,
        email: guardian.email,
        phone: guardian.phone,
        address: guardian.address
      };
      setValue('guardian', guardianValues);
      setValue('gender', gender);
      setValue('language', language);
      setValue('address', address);
      setValue('healthNotes', health_notes);
      setValue('inspiration', inspiration);
      setValue('healthCondition', sponsorship_condition_id);
      setValue('type', sponsorship_type_id);
      setValue('image', image);
      setValue('dateOfBirth', parseISO(DOB));

      const sponsorshipValues = {
        duration,
        selectedCost: costData.find(item => item.amount === cost) ? cost : '',
        status,
        priorityId: sponsorship_priority_id
      };
      if (!sponsorshipValues.selectedCost) {
        sponsorshipValues.otherCost = cost;
      }
      setValue('sponsorship', sponsorshipValues);
    }
  }, [data, costData, isEdit, setValue]);

  // const handleRadioChange = e => {
  //   const { value, name } = e.target;
  //   setValue(name, value);
  // };
  const { upload, loading: uploadLoading } = useUpload();

  const handleSubmit = async data => {
    if (data.image && typeof data.image !== 'string') {
      try {
        data.image = await upload(data.image);
      } catch (e) {
        toast.error('Something went wrong! Please Try again later!');
        return;
      }
    } else {
      toast.error('Please upload beneficiary image');
      return;
    }

    const {
      firstName,
      middleName,
      lastName,
      gender,
      dateOfBirth,
      language,
      address,
      type,
      guardian,
      healthCondition,
      healthNotes,
      location,
      inspiration,
      image,
      sponsorship
    } = data;

    const formattedDateOfBirth = new Date(dateOfBirth).toISOString();
    const submitData = {
      DOB: formattedDateOfBirth,
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      gender,
      language,
      address,
      country: location.country,
      city: location.city,
      condition: location.condition,
      sponsorship_condition_id: healthCondition,
      health_notes: healthNotes,
      inspiration,

      sponsorship_type_id: type,

      duration: sponsorship.duration,
      cost: Number(sponsorship.selectedCost || sponsorship.otherCost),
      status: sponsorship.status,
      priorityId: sponsorship.priorityId,

      image: image,
      guardian: {
        first_name: guardian.firstName,
        middle_name: guardian.middleName,
        last_name: guardian.lastName,

        email: guardian.email,
        phone: guardian.phone,
        address: guardian.address,

        guardian_father_status_id: guardian.fatherStatus,
        guardian_mother_status_id: guardian.motherStatus,
        guardian_relationShip_id: guardian.relationship
      },
      branch_id: selectedBranchId,
      organisation_id: organization.id
    };
    onSubmit(submitData);
  };

  return (
    <FormProvider methods={methods} onSubmit={RHFHandleSubmit(handleSubmit)}>
      <Row className="pt-3 gx-3">
        <Col md={9}>
          <ProfileInformation
            sectionRef={sectionRefs[1]}
            sideNavLink={sideNavLinks[0]?.href?.substring(1)}
          />

          <SponsorshipTypeCard
            sectionRef={sectionRefs[2]}
            sideNavLink={sideNavLinks[1]?.href?.substring(1)}
          />

          <LocationCard
            sectionRef={sectionRefs[3]}
            sideNavLink={sideNavLinks[2]?.href?.substring(1)}
            country={country}
          />

          <HealthConditionCard
            sectionRef={sectionRefs[4]}
            sideNavLink={sideNavLinks[3]?.href?.substring(1)}
          />

          <GuardianDetails
            sectionRef={sectionRefs[5]}
            sideNavLink={sideNavLinks[4]?.href?.substring(1)}
          />

          <InspirationCard
            sectionRef={sectionRefs[6]}
            sideNavLink={sideNavLinks[5]?.href?.substring(1)}
          />

          <SponsorshipDurationCard
            sectionRef={sectionRefs[7]}
            sideNavLink={sideNavLinks[6]?.href?.substring(1)}
          />

          <SponsorshipCostCard
            setValue={setValue}
            selectedCost={selectedCost}
            otherCost={otherCost}
            sectionRef={sectionRefs[8]}
            sideNavLink={sideNavLinks[7]?.href?.substring(1)}
          />

          <SponsorshipStatus
            sectionRef={sectionRefs[8]}
            sideNavLink={sideNavLinks[8]?.href?.substring(1)}
          />

          <Card className="border shadow-none mb-3">
            <Card.Body className="d-flex flex-row justify-content-end">
              <LoadingButton
                loading={loading || uploadLoading}
                type="submit"
                className="w-100 w-sm-25"
              >
                {isEdit ? 'Update' : 'Add'}
              </LoadingButton>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} className="order-0 order-md-1 d-none d-md-block">
          <Card
            className={'mb-3 sticky-top border shadow-none'}
            style={{ top: stickyStatus ? '5rem' : '0' }}
          >
            <Card.Body>
              <div>
                <Nav className="flex-column" variant="pills">
                  {sideNavLinks?.map(item => (
                    <Nav.Link
                      key={item.id}
                      href={item.href}
                      className={`${
                        activeSection === item.id
                          ? 'current custom-nav-link'
                          : 'custom-nav-link'
                      }`}
                      active={activeSection >= item.id}
                    >
                      {item.title}
                    </Nav.Link>
                  ))}
                </Nav>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </FormProvider>
  );
};

BeneficiaryForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  loading: PropTypes.bool,
  data: PropTypes.object,
  costData: PropTypes.array
};

export default BeneficiaryForm;
